import Cookies from 'js-cookie';
import { handleApiErrors } from '../util/HandleApiErrors';
import { getActiveOrg } from '../util/UserUtils';

const clean = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export default class Task {
  static async create(task) {
    task['organization_id'] = getActiveOrg();
    await fetch(`${process.env.REACT_APP_API_BASE}/tasks`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(task),
    }).then(handleApiErrors);
  }

  static async list(filters = {}, filtersListString) {
    filters['organization_id'] = `eq::${getActiveOrg()}`;
    if (!filters.page_size) {
      filters.page_size = 10;
    }
    if (!filters.page) {
      filters.page = 1;
    }
    let params = new URLSearchParams(clean(filters)).toString();
    if (filtersListString) params += filtersListString;
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/v2/tasks?${params}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    );
    return response.json();
  }

  static async show(id) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/tasks/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  }

  static async edit(task) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/tasks/${task.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(task),
      }
    );
    return response.json();
  }

  static async delete(task_id) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/tasks/${task_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  }
}
