import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import DolphinAppointmentClasses from './dolphin';

export const dolphinQueryKeys = queryKeyFactory('dolphinAppointmentClasses');

export const useDolphinAppointmentClasses = (filters, options) => {
  return useQuery(
    dolphinQueryKeys.list(filters),
    () => DolphinAppointmentClasses.list(filters),
    {
      staleTime: 0,
      retry: 0,
      ...options,
    }
  );
};
