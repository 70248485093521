import { toJSON, toParamString } from '../queryClientConfig';
import Cookies from 'js-cookie';

export default class BillingResources {
  static async list(filters) {
    return await fetch(
      `${process.env.REACT_APP_CALL_TRACKING}/billings?${toParamString(filters)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async generateCSV(enterprise_id, setCsvObjectKey) {
    return await fetch(
      `${process.env.REACT_APP_CALL_TRACKING}/billings?${toParamString([
        ['page', 1],
        ['page_size', 10000],
        ['enterprise_id', enterprise_id],
        ['content_type', 'text/csv'],
      ])}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((resText) => {
        const objectKey = JSON.parse(resText)?.object_key;
        return setCsvObjectKey(objectKey);
      });
  }

  static async getCSVUrl(objectKey) {
    return await fetch(
      `${process.env.REACT_APP_CALL_TRACKING}/csv?${toParamString([
        ['object_key', objectKey],
      ])}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((resText) => {
        const objectKey = JSON.parse(resText)?.csv_url;
        return objectKey;
      });
  }
}
