import { Alert, Box, Grid, Typography } from '@mui/material';
import { SettingsCard } from '../../../pages/_settingsPagesComponents/SettingsCard';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  ArrowRightIcon,
  ValidateIcon,
  ValidateOutlineIcon,
} from '../../../theme/icons';

export default function SubmissionCard({
  data,
  isLoading,
  isSuccess,
  text,
  createFunction,
  isDisbled,
  notice,
}) {
  return (
    <Grid item>
      <SettingsCard
        onClick={() => !isDisbled && createFunction()}
        sx={{ cursor: `${isDisbled ? 'not-allowed' : 'pointer'}` }}
      >
        <Box sx={{ py: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isLoading ? (
              <ClipLoader size={25} color="#34D1B6" />
            ) : (
              <>
                {data?.message === '????????????????????????' || isSuccess ? ( // TODO: Change this to a proper check
                  <ValidateIcon
                    sx={{
                      color: ({ palette }) => palette.primary.main,
                    }}
                  />
                ) : (
                  <ValidateOutlineIcon
                    sx={{
                      color: ({ palette }) => palette.grey['500'],
                    }}
                  />
                )}
              </>
            )}
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              {text}
            </Typography>
          </Box>
          <ArrowRightIcon sx={{ '&:hover': { color: '#34D1B6' } }} />
        </Box>
        {Boolean(notice) && (
          <Alert severity="warning" sx={{ ml: 1 }}>
            {notice}
          </Alert>
        )}
      </SettingsCard>
    </Grid>
  );
}
