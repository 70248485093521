import { useUsersCreate } from '../services/users/mutations';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { Alert, Box, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import HookForm from '../components/HookForm';
import { SaveIcon } from '../theme/icons';
import { FIELD_WIDTH } from '../theme/fixedValues';
import {
  createYupResolver,
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER_OPTIONAL,
  USER_NAME,
  EMAIL_ADDRESS,
  BUSINESS_TITLE,
  JOB_POSITION,
} from '../components/HookForm/yupValidations';
import { useHistory } from 'react-router-dom';

const USERS_JOB_POSITIONS = [
  { value: 'other', label: 'Other' },
  { value: 'director', label: 'Director' },
  { value: 'gm', label: 'GM' },
  { value: 'vp', label: 'VP' },
  { value: 'ceo', label: 'CEO' },
  { value: 'cfo', label: 'CFO' },
  { value: 'general_counsel', label: 'General Counsel' },
];

const UsersCreateSettingsPage = () => {
  const { mutate: createUser, isLoading, isError, reset } = useUsersCreate();
  const history = useHistory();

  const onCreateUserSubmit = (formData) => {
    createUser(
      {
        first_name: formData[FIRST_NAME],
        last_name: formData[LAST_NAME],
        email: formData[USER_NAME],
        username: formData[USER_NAME],
        phone: formData[PHONE_NUMBER_OPTIONAL],
        business_title: formData[BUSINESS_TITLE],
        job_position: formData[JOB_POSITION],
      },
      {
        onSuccess: () => history.push('/settings/users'),
      }
    );
  };

  const defaultValues = {
    [FIRST_NAME]: '',
    [LAST_NAME]: '',
    [USER_NAME]: '',
    [PHONE_NUMBER_OPTIONAL]: null,
    [BUSINESS_TITLE]: '',
    [JOB_POSITION]: null,
  };

  const useFormProps = { defaultValues, resolver: createYupResolver(defaultValues) };

  return (
    <SettingsCard showLoading={isLoading}>
      <SettingsCard.Header>Create a New User</SettingsCard.Header>
      <SettingsCard.Main>
        <HookForm
          onSubmit={onCreateUserSubmit}
          useFormProps={useFormProps}
          useDirtyFormCheck
        >
          {isError && (
            <Alert severity="error" onClose={() => reset()}>
              There was an error creating your user. Try again.
            </Alert>
          )}
          <Box
            display="grid"
            direction="column"
            rowGap={2}
            sx={{ width: FIELD_WIDTH }}
          >
            <HookForm.TextField name={FIRST_NAME} label="First Name" />
            <HookForm.TextField name={LAST_NAME} label="Last Name" />
            <HookForm.TextField name={EMAIL_ADDRESS} label="Username" />
            <HookForm.PhoneNumberField
              name={PHONE_NUMBER_OPTIONAL}
              label="Phone Number"
            />
            <HookForm.TextField name={BUSINESS_TITLE} label="Business Title" />
            <HookForm.Select name={JOB_POSITION} label="Job Position">
              {USERS_JOB_POSITIONS.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </HookForm.Select>
            <LoadingButton
              type="submit"
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              sx={{ maxWidth: 'max-content' }}
            >
              Save
            </LoadingButton>
          </Box>
        </HookForm>
      </SettingsCard.Main>
    </SettingsCard>
  );
};

export default UsersCreateSettingsPage;
