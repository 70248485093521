import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  Box,
  Chip,
  Typography,
  AccordionActions,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  IconButton,
  CircularProgress,
} from '@mui/material';

import { useOrg } from '../../../contexts/OrgProvider';
import { useTaskSequencesList } from '../../services/taskSequences/queries';
import { useSavedSearchesEdit } from '../../services/savedSearches/mutations';
import { useOrganizationsList } from '../../services/organizations/queries';
import { useSourcesList } from '../../services/sources/queries';
import { useSavedSearchesList } from '../../services/savedSearches/queries';
import { useUsersCurrent } from '../../services/users/queries';
import { useUsersList } from '../../services/users/queries';
import { useCustomFields } from '../../../api/customFields/queries';
import { taskActionIdMap } from '../../services/tasks/tasksUtils';
import { formatIsoStringToDateTimeLocal } from '../../services/dateTime';
import { useFilters } from '../../services/hooks/useFilters';
import { parseTasksFilterParams } from './utils';

import { DeleteIcon, StarIcon } from '../../theme/icons';
import { DateFilter, deserializeSavedDateFilter } from '../Filters/DateFilter';
import {
  CreateSavedSearchDialog,
  DeleteSavedSearchDialog,
} from '../SavedSearchDialogs';
import {
  CustomFieldAccordion,
  FilterAccordion,
  FilterAccordionSummary,
  FilterOperator,
} from '../Filters/FilterAccordion';
import { fireDialog } from '../../components/Dialog';
import { useTeamsList } from '../../services/teams/queries';
import { getAccountName } from './utils';

const FilterBar = forwardRef(
  ({ setPage, setTasksFilterMap, setIsUsersLoading }, ref) => {
    useImperativeHandle(ref, () => ({
      changeFilterParams(params) {
        setFilterMap((prev) => ({
          ...prev,
          ...params,
        }));
      },
    }));

    const history = useHistory();
    const { search } = useLocation();
    const { taskIdParam } = useParams();
    const { id: orgId, isMasterAccount } = useOrg();

    const [selectedSavedSearchId, setSelectedSavedSearchId] = useState();
    const [sourceInputValue, setSourceInputValue] = useState('');
    const [causeTextFieldValueChange, setCauseTextFieldValueChange] = useState(
      false
    );
    const [canUseSourcesList, setCanUseSourcesList] = useState(false);
    const [canUseUsersList, setCanUseUsersList] = useState(false);
    const [canUseTaskSequencesList, setCanUseTaskSequencesList] = useState(false);
    const [filterOperators, setFilterOperators] = useState({});

    const parseQueryParams = () => {
      const params = new URLSearchParams(search);
      if (params.has('assigned_user_id[]') && !canUseUsersList)
        setCanUseUsersList(true);

      return params.entries().reduce((acc, [key, value]) => {
        acc.push([key, value]);
        return acc;
      }, []);
    };
    const [queryParams, setQueryParams] = useState([]);

    const {
      filterMap,
      setFilterMapValue,
      setFilterMap,
      clearFilterMap,
    } = useFilters({
      initialFilters: initialTasksFilterMapValues,
    });

    const createFilterMapFromSavedFilters = (savedFilters) => {
      const filters = [...savedFilters];
      let map = {};
      let customFieldsIdMap = {};
      filters?.forEach((savedFilter) => {
        const filter = [...savedFilter];
        const filterMapKey = filter[0].replace(/\[\]|[0-9]_/gi, '');
        const groupedFilters = [];

        if (filterMapKey === 'sql_operator') {
          filters.forEach((filter) => {
            if (Number.isInteger(parseInt(filter[0][0]))) {
              groupedFilters.push(filter);
            }
          });
          map['sql_operator'] = groupedFilters;
        }
        if (filterMapKey === 'task_action_id') {
          const taskActionId = filter[1];
          const taskActionMap = new Map([
            ['a50370fd-5b8b-4b87-ba15-744ec43840e6', 'Call'],
            ['37e7e6e4-7bdb-42b7-a32b-0b285ae3a9f6', 'Call'],
            ['0fcbfe42-8a33-411e-8dad-46b269a34ec1', 'Email'],
            ['1b09c81b-8384-49a0-85c5-2466aacf9d37', 'Text'],
            ['38212f0b-924f-4274-ad37-2086bc8d708d', 'Follow Up'],
            ['d9e9de16-0d12-4358-91b6-bc0145c32b08', 'Re-Engage'],
          ]);

          const newSelectedOption = {
            filterValue: taskActionId,
            name: taskActionMap.get(taskActionId),
          };
          if (!map['task_action_id']) {
            map['task_action_id'] = [newSelectedOption];
          } else {
            map['task_action_id'].push(newSelectedOption);
          }
        }
        if (filterMapKey === 'organization_id') {
          let filterId = filter[1];
          if (String(filter[1]).includes('::')) {
            const filterValues = filter[1].split('::');
            setFilterOperators({
              ...filterOperators,
              organization_id: filterValues[0],
            });
            filterId = filterValues[1];
          } else {
            setFilterOperators({ ...filterOperators, organization_id: 'eq' });
          }
          const found = accountsList?.find(
            ({ id }) => String(id) === String(filterId)
          );
          const newSelectedOption = {
            filterValue: String(filter[1]).includes('::')
              ? filter[1]
              : `eq::${filter[1]}`,
            name: found?.name,
          };
          if (!map['organization_id']) {
            map['organization_id'] = [newSelectedOption];
          } else {
            map['organization_id'].push(newSelectedOption);
          }
        }
        if (filterMapKey === 'task_sequence_id') {
          const filterId = filter[1];
          const found = taskSequencesList?.find(
            ({ id }) => String(id) === String(filterId)
          );
          const newSelectedOption = {
            filterValue: filterId,
            name: found?.name,
          };
          if (!map['task_sequence_id']) {
            map['task_sequence_id'] = [newSelectedOption];
          } else {
            map['task_sequence_id'].push(newSelectedOption);
          }
        }
        if (filterMapKey === 'task_sequence_name') {
          const newSelectedOption = {
            filterValue: filter[1],
            name: filter[1],
          };
          if (!map['task_sequence_name']) {
            map['task_sequence_name'] = [newSelectedOption];
          } else {
            map['task_sequence_name'].push(newSelectedOption);
          }
        }
        if (filterMapKey === 'source_name') {
          const newSelectedOption = {
            filterValue: filter[1],
            name: filter[1],
          };
          if (!map['source_name']) {
            map['source_name'] = [newSelectedOption];
          } else {
            map['source_name'].push(newSelectedOption);
          }
        }
        if (filterMapKey === 'canceled' || filterMapKey === 'completed') {
          const status = filter[0];
          map['status'] = status;
        }
        if (filterMapKey === 'lead_created_at_selector') {
          map['lead_created_at'] = deserializeSavedDateFilter(filters);
        }
        if (filterMapKey === 'completed_at_start') {
          const filterValue = filter[1];
          map['completed_at_start'] = filterValue;
        }
        if (filterMapKey === 'completed_at_end') {
          const filterValue = filter[1];
          map['completed_at_end'] = filterValue;
        }
        if (filterMapKey === 'assigned_user_id') {
          const filterId = filter[1];
          const userIdCleaned = String(filterId).replace('eq::', '');
          const found = usersList?.find(({ id }) => String(id) === userIdCleaned);
          const newSelectedOption = {
            filterValue: filterId,
            name: `${found?.first_name} ${found?.last_name}`,
          };
          if (!map['assigned_user_id']) {
            map['assigned_user_id'] = [newSelectedOption];
          } else {
            map['assigned_user_id'].push(newSelectedOption);
          }
        }
        if (filterMapKey === 'team_id') {
          const filterId = filter[1];
          const found = teamsList?.find(({ id }) => String(id) === String(filterId));
          const newSelectedOption = {
            filterValue: filterId,
            name: found?.name,
          };
          if (!map['team_id']) {
            map['team_id'] = [newSelectedOption];
          } else {
            map['team_id'].push(newSelectedOption);
          }
        }
        if (filterMapKey === 'lead_custom_field_values') {
          const customFieldString = filter[1];
          const customFieldSplit = String(customFieldString).split('::');
          const customFieldId = customFieldSplit[0];
          const operator = customFieldSplit[1];
          const filterValue = customFieldSplit[2];

          const newSelectedOption = {
            operator,
            filterValue,
          };
          customFieldsIdMap[customFieldId] = newSelectedOption;
        }
      });
      map['lead_custom_field_values'] = { customFieldsIdMap };
      return map;
    };
    const loadSavedSearch = (savedSearch, doClearFilterMap) => {
      if (doClearFilterMap) clearFilterMap();
      const savedFiltersMap = createFilterMapFromSavedFilters(savedSearch);

      Object.entries(savedFiltersMap).forEach(([filterMapKey, filterMapValue]) =>
        setFilterMapValue(filterMapKey, filterMapValue)
      );
      setCauseTextFieldValueChange((prev) => !prev);
    };

    const handleClearFilterMap = () => {
      history.push(`/tasks`);
      clearFilterMap();
      setSelectedSavedSearchId(null);
      setCauseTextFieldValueChange((prev) => !prev);
    };

    const { mutate: editSavedSearch } = useSavedSearchesEdit();
    const { data: accountsList } = useOrganizationsList();
    const { data: currentUser } = useUsersCurrent();
    const { data: teamsList, isLoading: isLoadingTeamsList } = useTeamsList();
    const {
      data: taskSequencesList,
      isLoading: taskSequencesListIsLoading,
    } = useTaskSequencesList([], {
      enabled: canUseTaskSequencesList,
    });
    const { data: sourcesList, isLoading: sourcesListIsLoading } = useSourcesList(
      [['name', sourceInputValue]],
      {
        enabled: canUseSourcesList,
      }
    );
    const { data: usersList, isLoading: usersListIsLoading } = useUsersList([], {
      enabled: canUseUsersList,
    });
    const { data: customFieldsList } = useCustomFields(
      [['organization_id', orgId]],
      {
        select: (data) => {
          return transformCustomFieldList(orgId, isMasterAccount, data);
        },
      }
    );
    const { data: savedSearchesList } = useSavedSearchesList(
      { user_id: currentUser.id, organization_id: orgId },
      {
        resourceClass: 'tasks',
      }
    );

    const [sequenceOptions, setSequenceOptions] = useState([]);
    const [sourcesOptions, setSourcesOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [teamsOptions, setTeamsOptions] = useState([]);

    const selectedAccountsSet = useSelectedAccountsSet(filterMap, accountsList);

    useEffect(() => {
      if (!taskSequencesList) {
        return;
      }
      let uniq = [...new Set(taskSequencesList.map((t) => t.name))];
      let options = uniq
        .sort()
        .filter((t) => t.trim() != '')
        .map((t) => {
          return { filterValue: t, name: t };
        });
      setSequenceOptions(options);
    }, [taskSequencesList]);

    useEffect(() => {
      if (!sourcesList) {
        return;
      }
      let uniq = [...new Set(sourcesList.map((t) => t.name))];
      let options = uniq
        .sort()
        .filter((t) => t.trim() != '')
        .map((t) => {
          return { filterValue: t, name: t };
        });
      setSourcesOptions(options);
    }, [sourcesList]);

    useEffect(() => {
      if (!usersList) return;

      const userFilterOptions = usersList?.reduce((acc, user) => {
        acc.push({
          filterValue: `${user.id}`,
          name: `${user.first_name} ${user.last_name}`,
        });
        return acc;
      }, []);

      setUserOptions(userFilterOptions);
      const savedSearch = savedSearchesList?.find(
        ({ id }) => id === selectedSavedSearchId
      );

      if (!!savedSearch) {
        const usersFilterValues = savedSearch.data.filter(
          ([key]) => key === 'assigned_user_id[]'
        );

        setFilterMapValue(
          'assigned_user_id',
          createFilterMapFromSavedFilters(usersFilterValues).assigned_user_id
        );
      }
      setIsUsersLoading(false);
    }, [usersList]);

    useEffect(() => {
      const teamsFilterOptions = teamsList
        ?.reduce((acc, team) => {
          if (
            selectedAccountsSet?.has(String(team.organization_id)) ||
            !selectedAccountsSet
          ) {
            const teamAccountName = getAccountName(team, accountsList);
            if (teamAccountName) {
              acc.push({
                filterValue: team.id,
                account: teamAccountName,
                name: team.name,
              });
            }
          }
          return acc;
        }, [])
        .sort((a, b) => String(a.account).localeCompare(String(b.account)));

      setTeamsOptions(teamsFilterOptions ?? []);
    }, [selectedAccountsSet, teamsList, accountsList]);

    useEffect(() => {
      setTasksFilterMap(filterMap);
      setPage(1);
    }, [filterMap]);

    useEffect(() => {
      const defaultSearch = savedSearchesList?.find(({ is_default }) => is_default);
      if (!!defaultSearch) {
        if (!selectedSavedSearchId) setSelectedSavedSearchId(defaultSearch?.id);

        loadSavedSearch(defaultSearch?.data, false);
      }
    }, [savedSearchesList, accountsList, taskSequencesList, usersList]);

    useEffect(() => {
      if (sourceInputValue.length > 2) {
        setCanUseSourcesList(true);
      } else {
        setCanUseSourcesList(false);
        setSourcesOptions([]);
      }
    }, [sourceInputValue]);

    useEffect(() => {
      if (queryParams.length > 0) {
        const queryParamsFiltersMap = createFilterMapFromSavedFilters(queryParams);

        Object.entries(
          queryParamsFiltersMap
        ).forEach(([filterMapKey, filterMapValue]) =>
          setFilterMapValue(filterMapKey, filterMapValue)
        );
        setCauseTextFieldValueChange((prev) => !prev);
      }
    }, [queryParams, accountsList, usersList]);

    useEffect(() => {
      if (typeof search === 'string' && search.length > 0) {
        setQueryParams(parseQueryParams());
      }
    }, [search]);

    return (
      <Box
        flexDirection="column"
        width={230}
        sx={{
          display: `${taskIdParam ? 'none' : 'flex'}`,
          border: ({ palette }) => `1px solid ${palette.grey[300]}`,
          borderRadius: '4px',
          backgroundColor: 'white',
        }}
      >
        <Box
          columnGap={1}
          sx={{
            borderRadius: 'inherit',
            minHeight: '39px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: '10px',
            backgroundColor: ({ palette }) => palette.grey[100],
            borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}`,
          }}
        >
          <Typography fontSize={14} fontWeight={500} sx={{ flexBasis: '100%' }}>
            Filters
          </Typography>
          <Button
            variant="outlined"
            color="inherit"
            sx={{
              backgroundColor: ({ palette }) => palette.common.white,
              minWidth: '40px',
              height: '25px',
              p: 0.5,
            }}
            onClick={() => {
              const [_, filterParams] = parseTasksFilterParams(
                filterMap,
                isMasterAccount,
                orgId
              );

              fireDialog((promiseProps) =>
                CreateSavedSearchDialog({
                  ...promiseProps,
                  resourceClass: 'tasks',
                  filterParams,
                  setSelectedSavedSearchId: (value) => {
                    //TODO remove all of these when OR ui implemented
                    filterMap.sql_operator = null;
                    setSelectedSavedSearchId(value);
                  },
                })
              );
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            sx={{
              backgroundColor: ({ palette }) => palette.common.white,
              minWidth: '40px',
              height: '25px',
              p: 0.5,
            }}
            onClick={handleClearFilterMap}
          >
            Clear
          </Button>
        </Box>
        <Box overflow="auto">
          <FilterAccordion defaultExpanded>
            <FilterAccordionSummary>Saved Searches</FilterAccordionSummary>
            <AccordionActions sx={{ alignItems: 'flex-start' }}>
              <Box width="100%" display="flex" flexDirection="column">
                {savedSearchesList?.length > 0 ? (
                  savedSearchesList.map((savedSearch) => (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 0.5,
                        ':hover': {
                          cursor: 'pointer',
                        },
                      }}
                      key={savedSearch.id}
                    >
                      <Box
                        flexBasis="100%"
                        onClick={() => {
                          if (
                            savedSearch.data.some(
                              ([key]) => key === 'assigned_user_id[]'
                            ) &&
                            !canUseUsersList
                          ) {
                            setIsUsersLoading(true);
                            setCanUseUsersList(true);
                          }
                          setSelectedSavedSearchId(savedSearch.id);
                          filterMap.sql_operator = null;
                          loadSavedSearch(savedSearch.data, true);
                        }}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={
                            selectedSavedSearchId == savedSearch.id ? '600' : ''
                          }
                        >
                          {savedSearch.name}
                        </Typography>
                      </Box>
                      <IconButton
                        onClick={() =>
                          editSavedSearch({
                            ...savedSearch,
                            is_default: !savedSearch.is_default,
                          })
                        }
                        size="small"
                        sx={{
                          padding: 0,
                          color: ({ palette }) =>
                            savedSearch.is_default
                              ? palette.warning.light
                              : palette.grey[300],
                        }}
                      >
                        <StarIcon />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          fireDialog((promiseProps) =>
                            DeleteSavedSearchDialog({
                              ...promiseProps,
                              savedSearchId: savedSearch.id,
                            })
                          )
                        }
                        sx={{ padding: 0 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))
                ) : (
                  <Typography fontSize={14}>No saved searches</Typography>
                )}
              </Box>
            </AccordionActions>
          </FilterAccordion>
          <DateFilter
            setSelectedSavedSearchId={setSelectedSavedSearchId}
            setFilterMapValue={setFilterMapValue}
            filterMap={filterMap}
            fieldName={'lead_created_at'}
          />
          <FilterAccordion>
            <FilterAccordionSummary>
              Action
              {filterMap?.task_action_id?.length > 0 && (
                <Chip
                  onDelete={() => {
                    setSelectedSavedSearchId(null);
                    filterMap.sql_operator = null;
                    setFilterMapValue('task_action_id', []);
                  }}
                  label={filterMap?.task_action_id.length}
                />
              )}
            </FilterAccordionSummary>
            <AccordionActions>
              <Autocomplete
                autoComplete
                multiple
                sx={{ width: '100%' }}
                value={filterMap?.task_action_id ?? []}
                onChange={(_, value) => {
                  setSelectedSavedSearchId(null);
                  filterMap.sql_operator = null;
                  setFilterMapValue('task_action_id', value);
                }}
                options={Object.entries(taskActionIdMap)
                  .filter(([_, actionId]) => actionId !== taskActionIdMap.Voicemail)
                  .map(([name, actionId]) => {
                    let newName;
                    if (name === 'FollowUp') {
                      newName = 'Follow Up';
                    } else if (name === 'ReEngage') {
                      newName = 'Re-Engage';
                    } else {
                      newName = name;
                    }

                    return {
                      filterValue: actionId,
                      name: newName,
                    };
                  })}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Action" placeholder="" />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.filterValue === value.filterValue
                }
              />
            </AccordionActions>
          </FilterAccordion>
          <FilterAccordion
            onChange={(_, expanded) => {
              setCanUseTaskSequencesList(expanded);
            }}
          >
            <FilterAccordionSummary>
              Task Sequence
              {filterMap.task_sequence_name.length > 0 && (
                <Chip
                  onDelete={() => {
                    setSelectedSavedSearchId(null);
                    setFilterMapValue('task_sequence_name', []);
                  }}
                  label={filterMap.task_sequence_name.length}
                />
              )}
            </FilterAccordionSummary>
            <AccordionActions>
              <Autocomplete
                autoComplete
                multiple
                sx={{ width: '100%' }}
                onChange={(_, value) => {
                  setSelectedSavedSearchId(null);
                  setFilterMapValue('task_sequence_name', value);
                }}
                value={filterMap.task_sequence_name ?? []}
                getOptionLabel={(option) => option.name}
                options={sequenceOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Task Sequence"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {taskSequencesListIsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.filterValue === value.filterValue
                }
              />
            </AccordionActions>
          </FilterAccordion>
          <FilterAccordion>
            <FilterAccordionSummary>
              Lead Source
              {filterMap.source_name.length > 0 && (
                <Chip
                  onDelete={() => {
                    setSelectedSavedSearchId(null);
                    setFilterMapValue('source_name', []);
                  }}
                  label={filterMap.source_name.length}
                />
              )}
            </FilterAccordionSummary>
            <AccordionActions>
              <Autocomplete
                autoComplete
                multiple
                sx={{ width: '100%' }}
                onChange={(_, value) => {
                  setSelectedSavedSearchId(null);
                  setFilterMapValue('source_name', value);
                }}
                onClose={() => {
                  setCanUseSourcesList(false);
                  setSourcesOptions([]);
                }}
                onInputChange={(_, v) => setSourceInputValue(v)}
                inputValue={sourceInputValue}
                value={filterMap.source_name ?? []}
                getOptionLabel={(option) => option.name}
                options={sourcesOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Source"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {sourcesListIsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.filterValue === value.filterValue
                }
              />
            </AccordionActions>
          </FilterAccordion>
          <FilterAccordion>
            <FilterAccordionSummary>
              Status
              {filterMap?.status && (
                <Chip
                  onDelete={() => {
                    setSelectedSavedSearchId(null);
                    filterMap.sql_operator = null;
                    setFilterMapValue('status', null);
                  }}
                  label={'1'}
                />
              )}
            </FilterAccordionSummary>
            <AccordionActions>
              <FormControl fullWidth size="small">
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select
                  label="Status"
                  labelId="status-select-label"
                  size="small"
                  value={filterMap?.status ?? ''}
                  onChange={(event) => {
                    setSelectedSavedSearchId(null);
                    filterMap.sql_operator = null;
                    setFilterMapValue(`status`, event.target.value);
                  }}
                >
                  {[
                    { status: 'canceled', label: 'Canceled' },
                    { status: 'completed', label: 'Completed' },
                  ].map(({ status, label }) => (
                    <MenuItem key={status} value={status}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </AccordionActions>
          </FilterAccordion>
          {filterMap.status === 'completed' && (
            <FilterAccordion disableGutters square>
              <FilterAccordionSummary
                sx={{
                  fontSize: 14,
                  '& .MuiAccordionSummary-content': {
                    display: 'flex',
                    justifyContent: 'space-between',
                  },
                }}
              >
                Completed at
                {(filterMap?.completed_at_start || filterMap?.completed_at_end) && (
                  <Chip
                    size="small"
                    onDelete={() => {
                      setFilterMapValue('completed_at_start', '');
                      setFilterMapValue('completed_at_end', '');
                      filterMap.sql_operator = null;
                    }}
                    label={1}
                  />
                )}
              </FilterAccordionSummary>
              <AccordionActions
                disableSpacing
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Box display="grid" direction="column" rowGap={2}>
                  <TextField
                    type="datetime-local"
                    label="From"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: formatIsoStringToDateTimeLocal(
                        filterMap?.completed_at_end
                      ),
                    }}
                    value={formatIsoStringToDateTimeLocal(
                      filterMap?.completed_at_start
                    )}
                    onChange={(e) => {
                      filterMap.sql_operator = null;
                      setSelectedSavedSearchId(null);
                      if (e.target.value === '')
                        setFilterMapValue('completed_at_start', '');
                      else
                        setFilterMapValue(
                          'completed_at_start',
                          new Date(e.target.value).toISOString()
                        );
                    }}
                  />
                  <TextField
                    type="datetime-local"
                    label="To"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      min: formatIsoStringToDateTimeLocal(
                        filterMap?.completed_at_start
                      ),
                    }}
                    value={formatIsoStringToDateTimeLocal(
                      filterMap?.completed_at_end
                    )}
                    onChange={(e) => {
                      filterMap.sql_operator = null;
                      setSelectedSavedSearchId(null);
                      if (e.target.value === '')
                        setFilterMapValue('completed_at_end', '');
                      else
                        setFilterMapValue(
                          'completed_at_end',
                          new Date(e.target.value).toISOString()
                        );
                    }}
                  />
                </Box>
              </AccordionActions>
            </FilterAccordion>
          )}

          <FilterAccordion
            onChange={(_, expanded) => {
              setCanUseUsersList(expanded);
            }}
          >
            <FilterAccordionSummary>
              Team
              {filterMap?.team_id.length > 0 && (
                <Chip
                  onDelete={() => {
                    setSelectedSavedSearchId(null);
                    filterMap.sql_operator = null;
                    setFilterMapValue('team_id', []);
                  }}
                  label={filterMap?.team_id.length}
                />
              )}
            </FilterAccordionSummary>
            <AccordionActions>
              <Autocomplete
                autoComplete
                multiple
                sx={{ width: '100%' }}
                value={filterMap?.team_id ?? []}
                onChange={(_, value) => {
                  setSelectedSavedSearchId(null);
                  filterMap.sql_operator = null;
                  setFilterMapValue('team_id', value);
                }}
                options={teamsOptions}
                groupBy={(option) => option.account}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Team"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingTeamsList ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.filterValue === value.filterValue
                }
              />
            </AccordionActions>
          </FilterAccordion>

          <FilterAccordion
            onChange={(_, expanded) => {
              setCanUseUsersList(expanded);
            }}
          >
            <FilterAccordionSummary>
              Assigned User
              {filterMap?.assigned_user_id?.length > 0 && (
                <Chip
                  onDelete={() => {
                    setSelectedSavedSearchId(null);
                    filterMap.sql_operator = null;
                    setFilterMapValue('assigned_user_id', []);
                  }}
                  label={filterMap?.assigned_user_id?.length}
                />
              )}
            </FilterAccordionSummary>
            <AccordionActions>
              <Autocomplete
                autoComplete
                multiple
                sx={{ width: '100%' }}
                value={filterMap?.assigned_user_id ?? []}
                onChange={(_, value) => {
                  setSelectedSavedSearchId(null);
                  filterMap.sql_operator = null;
                  setFilterMapValue('assigned_user_id', value);
                }}
                options={userOptions}
                groupBy={(option) => option.account}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {usersListIsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.filterValue === value.filterValue
                }
              />
            </AccordionActions>
          </FilterAccordion>
          {isMasterAccount && (
            <FilterAccordion>
              <FilterAccordionSummary>
                Account
                {filterMap?.organization_id?.length > 0 && (
                  <Chip
                    onDelete={() => {
                      setSelectedSavedSearchId(null);
                      filterMap.sql_operator = null;
                      setFilterMapValue('organization_id', []);
                    }}
                    label={filterMap?.organization_id?.length}
                  />
                )}
              </FilterAccordionSummary>
              <AccordionActions>
                <FilterOperator
                  filterName={'organization_id'}
                  filterOperators={filterOperators}
                  setFilterOperators={setFilterOperators}
                  setFilterMapValue={setFilterMapValue}
                  setSelectedSavedSearchId={setSelectedSavedSearchId}
                >
                  <Autocomplete
                    autoComplete
                    multiple
                    sx={{ width: '100%' }}
                    value={filterMap?.organization_id ?? []}
                    onChange={(_, value) => {
                      setSelectedSavedSearchId(null);
                      filterMap.sql_operator = null;
                      setFilterMapValue('organization_id', value);
                    }}
                    options={
                      accountsList?.map(({ id, name }) => ({
                        filterValue: `${
                          filterOperators.organization_id || 'eq'
                        }::${id}`,
                        name,
                      })) ?? []
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Account" />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.filterValue === value.filterValue
                    }
                  />
                </FilterOperator>
              </AccordionActions>
            </FilterAccordion>
          )}

          {customFieldsList?.map((customField) => (
            <CustomFieldAccordion
              causeTextFieldValueChange={causeTextFieldValueChange}
              key={customField.id}
              setSelectedSavedSearchId={(value) => {
                filterMap.sql_operator = null;
                setSelectedSavedSearchId(value);
              }}
              customField={customField}
              filterMap={filterMap}
              setFilterMapValue={setFilterMapValue}
              customFieldValueType={'lead_custom_field_values'}
            />
          ))}
        </Box>
      </Box>
    );
  }
);

const transformCustomFieldList = (orgId, isMasterAccount, data) => {
  return data?.items
    ?.filter(
      (customField) => !isMasterAccount || !customField.master_custom_field_id
    )
    ?.sort((a, b) => {
      if (a.organization_id == orgId && b.organization_id != orgId) {
        return false;
      } else if (a.organization_id != orgId && b.organization_id == orgId) {
        return true;
      }
      return b.order < a.order;
    });
};

const useSelectedAccountsSet = (filterMap, accountsList) => {
  const [selectedAccountsSet, setSelectedAccountsSet] = useState(null);

  useEffect(() => {
    if (filterMap.organization_id?.length > 0) {
      let set = new Set();

      if (String(filterMap.organization_id[0].filterValue).startsWith('eq::')) {
        filterMap.organization_id?.forEach(({ filterValue }) =>
          set.add(String(filterValue.split('::')[1]))
        );
      } else if (
        String(filterMap.organization_id[0].filterValue).startsWith('not_eq::')
      ) {
        const excludedIds = filterMap.organization_id.map(
          ({ filterValue }) => filterValue.split('::')[1]
        );
        accountsList?.forEach(({ id }) => {
          if (!excludedIds.includes(String(id))) {
            set.add(String(id));
          }
        });
      } else {
        filterMap.organization_id?.forEach(({ filterValue }) =>
          set.add(String(filterValue))
        );
      }

      setSelectedAccountsSet(set);
    } else {
      setSelectedAccountsSet(null);
    }
  }, [filterMap]);

  return selectedAccountsSet;
};

const initialTasksFilterMapValues = {
  task_action_id: [],
  task_sequence_id: [],
  task_sequence_name: [],
  lead_created_at: [],
  source_name: [],
  status: null,
  completed_at_start: '',
  completed_at_end: '',
  organization_id: [],
  assigned_user_id: [],
  team_id: [],
  lead_custom_field_values: { customFieldsIdMap: {} },
};

export default FilterBar;
