import { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { NavLinkButtonStyle } from '../theme/styled/NavLinkButtonStyle';
import {
  Box,
  Typography,
  List,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Grid,
} from '@mui/material';
import {
  UserSettingsIcon,
  NotificationIcon,
  CustomFieldsIcon,
  ConnectedMailboxesIcon,
  LeadSourcesIcon,
  PhoneIcon,
  LeadQualificationsIcon,
  CreditCardEditIcon,
  UsersListsIcon,
  DemoModeIcon,
  OrganizationsIcon,
  TemplatesIcon,
  ImportIcon,
  SequencesIcon,
  EnterpriseIcon,
  AccountIcon,
  LostReasonIcon,
  DownloadIcon,
  MissedPhone,
  FileSearchIcon,
  Integration,
  CalendarIcon,
  AgentIcon,
  FileTreeIcon,
} from '../theme/icons';
import { useOrg } from '../../contexts/OrgProvider';
import { useUsersCurrent } from '../services/users/queries';
import UserProfileSettingsPage from './UserProfileSettingsPage';
import FiltersSettingsPage from './FiltersSettingsPage';
import NotificationsSettingsPage from './NotificationsSettingsPage';
import ConnectedMailboxesSettingsPage from './ConnectedMailboxesSettingsPage';
import LeadSourcesSettingsPage from './LeadSourcesSettingsPage';
import AgentsShowSettingsPage from './AgentsShowSettingsPage';
import CustomFields from './CustomFields';
import LeadQualificationSettingsPage from './LeadQualificationSettingsPage';
import TeamListSettings from './TeamListSettings';
import TeamSettings from './TeamSettings';
import UsersListSettingsPage from './UsersListSettingsPage';
import BillingResourcesPage from './BillingResourcesPage';
import UsersShowSettingsPage from './UsersShowSettingsPage';
import UsersCreateSettingsPage from './UsersCreateSettingsPage';
import OrganizationsList from './OrganizationsList';
import LostReasons from './LostReasons';
import EnterprisePage from './EnterprisePage';
import AccountPage from './AccountPage';
import ResetDemoModePage from './ResetDemoModePage';
import DownloadsPage from './DownloadsPage';
import CallTracking from './CallTracking';
import CallTrackingList from './CallTrackingList';
import GooglePage from './GooglePage';
import FacebookPage from './FacebookPage';
import CallTrackingVisits from './CallTrackingVisits';
import IntegrationsPage from './IntegrationsPage';
import NexHealthList from './NexHealthPage';
import NylasPage from './NylasPage';
import CalendarPage from '../../MUI/pages/CalendarViewPage';
import FewShotsPage from '../../MUI/pages/FewShots';
import CallFlowPage from './CallFlowPage';
import CallFlowVisits from './CallFlowVisits';
import FewShotsEdit from '../../MUI/pages/FewShotsEdit';
import CalendarsList from './CalendarsList';
import Cloud9Page from '../../MUI/pages/Cloud9SettingsPage';
import AIModulesPage from './AIModulesPage';
import AIModulesEdit from './AIModulesEdit';
import AIModulesFunctionArguments from './AIModulesFunctionArguments';
import DolphinPage from '../../MUI/pages/DolphinSettings';

import ImportContainer from '../../components/pipeline/import/ImportContainer';
import UploadContainer from '../../components/pipeline/import/UploadContainer';
import PreviewContainer from '../../components/pipeline/import/PreviewContainer';
import CompleteContainer from '../../components/pipeline/import/CompleteContainer';
import SequenceList from '../../components/sequences/SequenceList';
import SequenceShow from '../../components/sequences/SequenceShow';
import TemplatesPage from './TemplatesPage';
import EditTemplateView from '../../components/templates/EditTemplateView';
import CreateTemplateView from '../../components/templates/CreateTemplateView';

const USER_PROFILE = 'user-profile';
const FILTERS = 'filters';
const USERS_LIST = 'users';
const USERS_SHOW = 'users/edit/:userId';
const USERS_CREATE = 'users/create';
const NOTIFICATIONS = 'notifications';
const CUSTOM_FIELDS = 'custom-fields';
const CONNECTED_MAILBOXES = 'connected-mailboxes';
const LEAD_SOURCES = 'lead-sources';
const AGENTS_SHOW = 'teams/:teamId/agents/:agentId';
const LEAD_QUALIFICATION = 'lead-qualifications';
const DEMO_MODE = 'demo-mode';
const BILLING = 'billing';
const TEAMS = 'teams';
const ORGS = 'accounts';
const ENTERPRISE = 'enterprise';
const ACCOUNT = 'account-settings';
const TEMPLATES = `templates`;
const TEMPLATE_EDIT = `templates/edit/:templateId`;
const TEMPLATE_CREATE = `templates/create`;
const IMPORT = 'import';
const UPLOAD = 'import/upload';
const PREVIEW = 'import/preview';
const COMPLETE = 'import/complete';
const SEQUENCES = 'sequences';
const SEQUENCES_SHOW = 'sequences/:sequenceId';
const LOST_REASONS = 'lost-reasons';
const CSV_DOWNLOADS = 'downloads';
const CALL_TRACKING = 'call-tracking';
const PHONE_ID_SETTINGS = 'phone-id';
const WEBHOOK_INTEGRATIONS = 'integrations';
const NEXHEALTH_INTEGRATION = 'integrations/nexhealth';
const NYLAS_INTEGRATION = 'integrations/nylas';
const GOOGLE_ADS_INTEGRATION = 'integrations/google';
const FACEBOOK_ADS_INTEGRATION = 'integrations/facebook';
const CALENDAR_VIEW = 'calendars';
const AGENT = 'AI';
const CALL_FLOW = 'call-flow';
const CLOUD9 = 'integrations/cloud9';
const AI_MODULES = 'modules';
const AI_MODULES_FUNCTION = 'function';
const DOLPHIN = 'integrations/dolphin';

export default function SettingsPage() {
  const { data: user } = useUsersCurrent();
  const { path, url } = useRouteMatch();
  const { is_demo, isEnterpriseUser, isMasterAccount } = useOrg();
  const [routes, setRoutes] = useState([
    { to: USER_PROFILE, text: 'User Profile', icon: <UserSettingsIcon /> },
    { to: NOTIFICATIONS, text: 'Notifications', icon: <NotificationIcon /> },
    {
      to: CONNECTED_MAILBOXES,
      text: 'Email',
      icon: <ConnectedMailboxesIcon />,
    },
    {
      to: CSV_DOWNLOADS,
      text: 'Downloads',
      icon: <DownloadIcon />,
    },
  ]);
  const [enterpriseRoutes, setEnterpriseRoutes] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const newRoutes = [...routes];
    const entRoutes = [];
    if (isEnterpriseUser && isMasterAccount) {
      entRoutes.push({
        to: ENTERPRISE,
        text: 'Enterprise',
        icon: <EnterpriseIcon />,
      });
      entRoutes.push({
        to: ORGS,
        text: 'Accounts',
        icon: <OrganizationsIcon />,
      });
    }
    if (is_user_administrator()) {
      newRoutes.push({
        to: ACCOUNT,
        text: 'Account',
        icon: <AccountIcon />,
      });
      newRoutes.push({
        to: SEQUENCES,
        text: 'Sequences',
        icon: <SequencesIcon />,
      });
      newRoutes.push({
        to: TEMPLATES,
        text: 'Templates',
        icon: <TemplatesIcon />,
      });
      newRoutes.push({
        to: FILTERS,
        text: 'Filters',
        icon: <FileSearchIcon />,
      });
      newRoutes.push({
        to: CUSTOM_FIELDS,
        text: 'Custom Fields',
        icon: <CustomFieldsIcon />,
      });
      newRoutes.push({
        to: LEAD_QUALIFICATION,
        text: 'Lead Qualifications',
        icon: <LeadQualificationsIcon />,
      });
      newRoutes.push({
        to: LEAD_SOURCES,
        text: 'Lead Sources',
        icon: <LeadSourcesIcon />,
      });
      newRoutes.push({
        to: LOST_REASONS,
        text: 'Lost Reasons',
        icon: <LostReasonIcon />,
      });
      newRoutes.push({ to: USERS_LIST, text: 'Users', icon: <UsersListsIcon /> });
      newRoutes.push({ to: TEAMS, text: 'Teams', icon: <PhoneIcon /> });
      newRoutes.push({
        to: CALL_FLOW,
        text: 'Call Flows',
        icon: <FileTreeIcon />,
      });
      newRoutes.push({
        to: CALL_TRACKING,
        text: 'Call Tracking',
        icon: <MissedPhone />,
      });
      newRoutes.push({
        to: WEBHOOK_INTEGRATIONS,
        text: 'Integrations',
        icon: <Integration />,
      });
      newRoutes.push({ to: BILLING, text: 'Billing', icon: <CreditCardEditIcon /> });
      newRoutes.push({
        to: CALENDAR_VIEW,
        text: 'Calendars',
        icon: <CalendarIcon />,
      });
      user?.admin && newRoutes.push({ to: AGENT, text: 'AI', icon: <AgentIcon /> });
      newRoutes.push({
        to: IMPORT,
        text: 'Import',
        icon: <ImportIcon />,
      });
    }
    if (is_demo)
      newRoutes.push({
        to: DEMO_MODE,
        text: 'Demo Mode',
        icon: <DemoModeIcon />,
      });
    setRoutes(newRoutes);
    setEnterpriseRoutes(entRoutes);
  }, []);

  const is_user_administrator = () => {
    return user?.user_administrator === true || isEnterpriseUser;
  };
  useEffect(() => {
    const classname = document.getElementById('settings-page1');
    classname.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }, [location]);

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      gap={2}
      id="settings-page1"
      name="settings-page"
    >
      <Typography fontSize={18} fontWeight={700}>
        Settings
      </Typography>
      <Grid container columnGap={3} sx={{ flex: 1 }}>
        <Grid item xs={12} md="auto">
          <List sx={{ p: 0, minWidth: 'max(20vw,200px)' }}>
            {routes.map(({ to, text, icon }) => (
              <NavLinkButtonStyle
                key={to}
                isActive={(route) => route?.url.includes(to)}
                component={NavLink}
                to={`${url}/${to}`}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{text}</ListItemText>
              </NavLinkButtonStyle>
            ))}
            {isEnterpriseUser && isMasterAccount && (
              <ListSubheader>Enterprise</ListSubheader>
            )}
            {enterpriseRoutes
              .sort((a, b) => (a.text > b.text ? 1 : -1))
              .map(({ to, text, icon }) => (
                <NavLinkButtonStyle
                  key={to}
                  isActive={(route) => route?.url.includes(to)}
                  component={NavLink}
                  to={`${url}/${to}`}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{text}</ListItemText>
                </NavLinkButtonStyle>
              ))}
          </List>
        </Grid>
        <Grid
          item
          sx={{
            flex: 1,
            maxWidth: { xs: '100%', lg: 'max(1000px, 50vw)', minWidth: 0 },
          }}
        >
          <Switch>
            {is_demo && (
              <Route
                exact
                path={`${path}/${DEMO_MODE}`}
                component={ResetDemoModePage}
              />
            )}
            {isEnterpriseUser && isMasterAccount && (
              <Route exact path={`${path}/${ORGS}`} component={OrganizationsList} />
            )}
            {isEnterpriseUser && isMasterAccount && (
              <Route
                exact
                path={`${path}/${ENTERPRISE}`}
                component={EnterprisePage}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${FILTERS}`}
                component={FiltersSettingsPage}
              />
            )}
            <Route
              exact
              path={`${path}/${USER_PROFILE}`}
              component={UserProfileSettingsPage}
            />
            <Route
              exact
              path={`${path}/${NOTIFICATIONS}`}
              component={NotificationsSettingsPage}
            />
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${NOTIFICATIONS}/:userId`}
                component={NotificationsSettingsPage}
              />
            )}
            <Route
              exact
              path={`${path}/${CONNECTED_MAILBOXES}`}
              component={ConnectedMailboxesSettingsPage}
            />
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${USERS_LIST}`}
                component={UsersListSettingsPage}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${USERS_CREATE}`}
                component={UsersCreateSettingsPage}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${USERS_SHOW}`}
                component={UsersShowSettingsPage}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${CUSTOM_FIELDS}`}
                component={CustomFields}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${LEAD_SOURCES}`}
                component={LeadSourcesSettingsPage}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${AGENTS_SHOW}`}
                component={AgentsShowSettingsPage}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${LEAD_QUALIFICATION}`}
                component={LeadQualificationSettingsPage}
              />
            )}
            {is_user_administrator() && (
              <Route exact path={`${path}/${TEAMS}`} component={TeamListSettings} />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${TEAMS}/:teamId`}
                component={TeamSettings}
              />
            )}
            {is_user_administrator() && (
              <Route exact path={`${path}/${CALL_FLOW}`} component={CallFlowPage} />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${CALL_FLOW}/:callFlowId`}
                component={CallFlowVisits}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${CALL_TRACKING}`}
                component={CallTrackingList}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${CALL_TRACKING}/:callTrackingId`}
                component={CallTrackingVisits}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${BILLING}`}
                component={BillingResourcesPage}
              />
            )}
            {is_user_administrator() && (
              <Route exact path={`${path}/${TEMPLATES}`} component={TemplatesPage} />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${TEMPLATE_CREATE}`}
                component={CreateTemplateView}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${TEMPLATE_EDIT}`}
                component={EditTemplateView}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${LOST_REASONS}`}
                component={LostReasons}
              />
            )}
            {is_user_administrator() && (
              <Route exact path={`${path}/${IMPORT}`} component={ImportContainer} />
            )}
            <Route
              exact
              path={`${path}/${CSV_DOWNLOADS}`}
              component={DownloadsPage}
            />
            {is_user_administrator() && (
              <Route exact path={`${path}/${UPLOAD}`} component={UploadContainer} />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${PREVIEW}`}
                component={PreviewContainer}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${COMPLETE}`}
                component={CompleteContainer}
              />
            )}
            {is_user_administrator() && (
              <Route exact path={`${path}/${SEQUENCES}`} component={SequenceList} />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${SEQUENCES_SHOW}`}
                component={SequenceShow}
              />
            )}
            {is_user_administrator() && (
              <Route exact path={`${path}/${ACCOUNT}`} component={AccountPage} />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${WEBHOOK_INTEGRATIONS}`}
                component={IntegrationsPage}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${NEXHEALTH_INTEGRATION}`}
                component={NexHealthList}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${NYLAS_INTEGRATION}`}
                component={NylasPage}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${GOOGLE_ADS_INTEGRATION}`}
                component={GooglePage}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${FACEBOOK_ADS_INTEGRATION}`}
                component={FacebookPage}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${CALENDAR_VIEW}`}
                component={CalendarsList}
              />
            )}
            {is_user_administrator() && (
              <Route
                exact
                path={`${path}/${CALENDAR_VIEW}/:calendarID`}
                component={CalendarPage}
              />
            )}
            {user?.admin && (
              <Route exact path={`${path}/${AGENT}`} component={FewShotsPage} />
            )}
            {user?.admin && (
              <Route
                exact
                path={`${path}/${AGENT}/:agentID`}
                component={FewShotsEdit}
              />
            )}
            {user?.admin && (
              <Route
                exact
                path={`${path}/${AI_MODULES}`}
                component={AIModulesPage}
              />
            )}
            {user?.admin && (
              <Route
                exact
                path={`${path}/${AI_MODULES}/:moduleID`}
                component={AIModulesEdit}
              />
            )}
            {user?.admin && (
              <Route
                exact
                path={`${path}/${AI_MODULES_FUNCTION}/:functionID`}
                component={AIModulesFunctionArguments}
              />
            )}
            {is_user_administrator() && (
              <Route exact path={`${path}/${CLOUD9}`} component={Cloud9Page} />
            )}
            {is_user_administrator() && (
              <Route exact path={`${path}/${DOLPHIN}`} component={DolphinPage} />
            )}
            <Route
              path={`${path}/`}
              component={() => (
                <Redirect to={{ pathname: `${path}/${USER_PROFILE}` }} />
              )}
            />
          </Switch>
        </Grid>
      </Grid>
    </Box>
  );
}
