/*
  Provide all icons to app through this file and preferrable with this library
  https://github.com/TeamWertarbyte/mdi-material-ui#readme
  https://materialdesignicons.com/
*/
import {
  HomeOutline,
  AccountCheckOutline,
  AccountOutline,
  TextBoxOutline,
  ChartArc,
  CloudUploadOutline,
  CloudDownloadOutline,
  CogOutline,
  FormatListNumbered,
  Logout,
  History,
  AccountCogOutline,
  BellOutline,
  Draw,
  MailboxOutline,
  AccountQuestionOutline,
  ArrowCollapseLeft,
  Plus,
  PencilOutline,
  DeleteOutline,
  ContentSaveOutline,
  PhoneOutline,
  DotsGrid,
  AccountArrowRightOutline,
  CreditCardEditOutline,
  AccountGroupOutline,
  AccountMultiplePlusOutline,
  Check,
  CheckboxBlankOutline,
  CheckboxMarkedOutline,
  LockReset,
  LockOutline,
  OfficeBuildingCogOutline,
  PhoneForwardOutline,
  AccountPlusOutline,
  AccountMinusOutline,
  NoteOutline,
  PhoneIncomingOutline,
  PhoneOutgoingOutline,
  PhoneRemoveOutline,
  PhoneCheckOutline,
  PhoneCancelOutline,
  PhoneAlertOutline,
  MessageTextOutline,
  EmailOutline,
  EmailSendOutline,
  EmailReceiveOutline,
  EmailMinusOutline,
  ClipboardRemoveOutline,
  ClipboardOutline,
  ClipboardCheckOutline,
  ClipboardClockOutline,
  Cash,
  Refresh,
  FilterOutline,
  AlertCircleOutline,
  Star,
  ClockAlertOutline,
  Magnify,
  DotsVertical,
  CloseCircleOutline,
  HelpCircleOutline,
  HomeGroup,
  HomeAccount,
  PhoneAlert,
  ChevronDown,
  AccountCancelOutline,
  Share,
  WindowMinimize,
  Filter,
  FilterOff,
  PhoneMissedOutline,
  FileSearch,
  PhoneIncoming,
  ThumbDownOutline,
  ThumbUpOutline,
  PlayCircleOutline,
  PauseCircleOutline,
  FastForward10,
  Rewind10,
  BookmarkOutline,
  DownloadOutline,
  Microphone,
  HumanGreeting,
  PhoneDial,
  Menu,
  MenuDown,
  Voicemail,
  PhoneHangup,
  Creation,
  ArrowDown,
  Connection,
  CalendarMonthOutline,
  FaceAgent,
  FileTree,
  ContentCopy,
  CheckCircle,
  CheckCircleOutline,
  ArrowRight,
} from 'mdi-material-ui';

// NAV ICONS
export const FileTreeIcon = FileTree;
export const ArrowDownIcon = ArrowDown;
export const CreationIcon = Creation;
export const PhoneHangupIcon = PhoneHangup;
export const VoicemailIcon = Voicemail;
export const MenuIcon = Menu;
export const MenuDownIcon = MenuDown;
export const HumanGreetingIcon = HumanGreeting;
export const PhoneDialIcon = PhoneDial;
export const MicrophoneIcon = Microphone;
export const PhoneCheckOutlineIcon = PhoneCheckOutline;
export const FileSearchIcon = FileSearch;
export const HomeIcon = HomeOutline;
export const TasksIcon = AccountCheckOutline;
export const LeadsIcon = AccountOutline;
export const ReportsIcon = ChartArc;
export const TemplatesIcon = TextBoxOutline;
export const SequencesIcon = FormatListNumbered;
export const ActivityIcon = History;
export const ImportIcon = CloudUploadOutline;
export const DownloadIcon = CloudDownloadOutline;
export const SettingsIcon = CogOutline;
export const LogOutIcon = Logout;
export const CollapseIcon = ArrowCollapseLeft;
export const UserSettingsIcon = AccountCogOutline;
export const UsersListsIcon = AccountGroupOutline;
export const NotificationIcon = BellOutline;
export const CustomFieldsIcon = Draw;
export const ConnectedMailboxesIcon = MailboxOutline;
export const LeadSourcesIcon = AccountQuestionOutline;
export const LeadQualificationsIcon = AccountArrowRightOutline;
export const PlusIcon = Plus;
export const EditIcon = PencilOutline;
export const DeleteIcon = DeleteOutline;
export const SaveIcon = ContentSaveOutline;
export const PhoneIcon = PhoneOutline;
export const DragIcon = DotsGrid;
export const CreditCardEditIcon = CreditCardEditOutline;
export const CheckIcon = Check;
export const CheckboxEmpty = CheckboxBlankOutline;
export const CheckboxChecked = CheckboxMarkedOutline;
export const DemoModeIcon = LockReset;
export const OrganizationsIcon = HomeGroup;
export const EnterpriseIcon = OfficeBuildingCogOutline;
export const EmailIcon = EmailOutline;
export const ExclamationIcon = AlertCircleOutline;
export const StarIcon = Star;
export const ClockAlertIcon = ClockAlertOutline;
export const LockIcon = LockOutline;
export const SearchIcon = Magnify;
export const DotMenuIcon = DotsVertical;
export const TaskCanceledIcon = ClipboardRemoveOutline;
export const TaskCompletedIcon = ClipboardCheckOutline;
export const TaskOpenIcon = ClipboardOutline;
export const TaskFuturueIcon = ClipboardClockOutline;
export const XIcon = CloseCircleOutline;
export const SupportIcon = HelpCircleOutline;

export const AcnIcon = PhoneForwardOutline;
export const AcnDeclinedIcon = PhoneCancelOutline;
export const AcnUnansweredIcon = PhoneAlertOutline;
export const LeadCreatedIcon = AccountPlusOutline;
export const LeadDeletedIcon = AccountMinusOutline;
export const LeadDuplicatedIcon = AccountMultiplePlusOutline;
export const NoteIcon = NoteOutline;
// export const PhoneInboundIcon = PhoneIncomingOutline;
// export const PhoneOutboundIcon = PhoneOutgoingOutline;
// export const PhoneUnansweredIcon = PhoneRemoveOutline;
export const TextIcon = MessageTextOutline;
export const EmailInboundIcon = EmailReceiveOutline;
export const EmailOutboundIcon = EmailSendOutline;
export const EmailUnsubscribedIcon = EmailMinusOutline;
export const MoneyIcon = Cash;
export const RefreshIcon = Refresh;
export const FilterIcon = FilterOutline;
export const AccountIcon = HomeAccount;
export const BadPhoneIcon = PhoneAlert;
export const ChevronDownIcon = ChevronDown;
export const LostReasonIcon = AccountCancelOutline;
export const ShareIcon = Share;
export const MinimizeIcon = WindowMinimize;
export const FilterON = Filter;
export const FilterOFF = FilterOff;
export const MissedPhone = PhoneMissedOutline;
export const CallTrackingIcon = PhoneIncoming;
export const Thumbup = ThumbUpOutline;
export const Thumbdown = ThumbDownOutline;
export const CallsPlay = PlayCircleOutline;
export const FastForward = FastForward10;
export const Backward = Rewind10;
export const CallsPause = PauseCircleOutline;
export const BookmarkIcon = BookmarkOutline;
export const DownloadDirect = DownloadOutline;
export const Integration = Connection;
export const CalendarIcon = CalendarMonthOutline;
export const AgentIcon = FaceAgent;
export const SlotsCopy = ContentCopy;
export const ValidateIcon = CheckCircle;
export const ValidateOutlineIcon = CheckCircleOutline;
export const ArrowRightIcon = ArrowRight;
