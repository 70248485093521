import { useQuery } from 'react-query';
import { useOrg } from '../../../contexts/OrgProvider';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';
import Activity from './Activity';

const activityQueryKeys = queryKeyFactory('activity');

export const useActivitiesList = (filters = [], options) => {
  const { id: orgId, isMasterAccount } = useOrg();
  return useQuery(
    activityQueryKeys.list(filters),
    () => {
      if (!isMasterAccount) filters.push(['organization_id[]', `eq::${orgId}`]);
      return Activity.list(filters);
    },
    {
      staleTime: ONE_HOUR,
      keepPreviousData: true,
      ...options,
    }
  );
};
