import Cookies from 'js-cookie';
import { toJSON } from '../queryClientConfig';

export default class AdsAccount {
  static async list(filters) {
    return await fetch(
      `${process.env.REACT_APP_IVR}/ads_accounts?${new URLSearchParams(
        filters
      ).toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async edit(data) {
    return await fetch(`${process.env.REACT_APP_IVR}/ads_accounts/${data.id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  static async delete(id) {
    return await fetch(`${process.env.REACT_APP_IVR}/ads_accounts/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  static async listFacebookAdAccounts(filters) {
    return await fetch(
      `${process.env.REACT_APP_CALL_TRACKING}/meta/ad_accounts?${new URLSearchParams(
        filters
      ).toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async listActions(filters) {
    return await fetch(
      `${
        process.env.REACT_APP_CALL_TRACKING
      }/meta/ad_account_pixels?${new URLSearchParams(filters).toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }
}
