import { useState, useRef } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import FilterButtonWrapper from '../components/Filters/FilterButtonWrapper';
import FilterBar from '../components/Reports/Overview/FilterBar';
import { initialReportsFilterMapValues } from '../components/Reports/Overview/FilterBar';
import ReportCard from '../components/Reports/ReportCard';
import { useOrg } from '../../contexts/OrgProvider';
import { parseFilterParams } from '../components/Filters/utils';

const ReportsOverview = () => {
  const reportGroups = {
    // 'messagingApiBase-Active Call Notifications': [],
    'apiBase-Active Call Notifications': [
      { type: 'acn_accepted', name: 'Active Call Notifications Accepted' },
      { type: 'acn', name: 'Active Call Notifications' },
      { type: 'acn_accept_rate', name: 'Active Call Notifications Accept Rate' },
    ],
    'apiBase-Calls': [
      { type: 'call_answered', name: 'Calls Answered' },
      { type: 'call_received', name: 'Calls Received' },
      { type: 'call_made', name: 'Calls Made' },
      { type: 'call_answer_rate', name: 'Calls Answer Rate' },
    ],
    'apiBase-Emails': [
      { type: 'email_sent', name: 'Emails Sent' },
      { type: 'email_received', name: 'Emails Received' },
      { type: 'email_engagement_rate', name: 'Email Engagement Rate' },
    ],
    'apiBase-Text Messages': [
      { type: 'text_messages_sent', name: 'Text Messages Sent' },
      { type: 'text_messages_received', name: 'Text Messages Received' },
      {
        type: 'text_messages_engagement_rate',
        name: 'Text Message Engagement Rate',
      },
    ],
    'apiBase-Leads': [
      { type: 'lead_worth', name: 'Leads are Worth' },
      { type: 'win_worth', name: 'Wins are Worth' },
      { type: 'pipeline_value', name: 'Current Pipeline Value' },
      { type: 'lead_generated', name: 'Total Leads Generated' },
      { type: 'lead_generated_by_actor', name: 'Total Leads Generated by Creator' },
      { type: 'lead_won', name: 'Total Marked Won' },
    ],
  };

  const filterBarRef = useRef();
  const { id: orgId, isMasterAccount } = useOrg();

  const [showFilters, setFilterToggle] = useState(window.innerWidth > 768);
  const [reportsFilterMap, setReportsFilterMap] = useState(
    initialReportsFilterMapValues
  );

  const isDateRangeSelected = () => {
    if (!reportsFilterMap['date_range']?.length) return false;

    const dateFilters = reportsFilterMap['date_range'][0];

    return Boolean(
      (dateFilters.filterValue === '16' && dateFilters.startDate) ||
        (dateFilters.filterValue === '16' && dateFilters.endDate) ||
        dateFilters.filterValue !== '16'
    );
  };

  const createParams = (apiGroup, type) => {
    const [_, filters] = parseFilterParams(reportsFilterMap);
    filters.push(['type', type]);

    const params = {
      apiGroup,
      canUse: isDateRangeSelected(),
      filters,
    };
    if (!isMasterAccount) {
      params.filters.push(['organization_id', `eq::${orgId}`]);
    }
    return params;
  };

  const currentPath = window.location.pathname;
  const shouldShowFilters = currentPath === '/reports/overview';

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <Typography fontSize={18} fontWeight={700}>
            Reports Overview
          </Typography>
          <Box display="flex" alignItems="center">
            <Box sx={{ marginLeft: '10px' }}>
              {shouldShowFilters && (
                <FilterButtonWrapper
                  showFilters={showFilters}
                  setFilterToggle={setFilterToggle}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" height="0%" width="100%" flexBasis="100%" gap={2}>
        {showFilters ? (
          <FilterBar ref={filterBarRef} setReportsFilterMap={setReportsFilterMap} />
        ) : (
          <></>
        )}
        <Box>
          {Object.entries(reportGroups).map(([apiGroup, reportTypes]) => (
            <Box key={apiGroup}>
              <Box sx={{ my: 3 }}>
                {apiGroup.split('-')[1]}
                <Divider />
              </Box>
              <Box
                sx={{
                  pl: 3,
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'start',
                  gap: 3,
                }}
              >
                {reportTypes.map((report) => (
                  <ReportCard
                    key={report.type}
                    report={report}
                    params={createParams(apiGroup, report.type)}
                  />
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ReportsOverview;
