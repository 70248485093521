import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { ExclamationIcon } from '../../theme/icons';
import { Box } from '@mui/system';

const AuthenticatedPlayer = ({ source }) => {
  const [url, setUrl] = useState();
  const [isInError, setIsInError] = useState(false);

  const errorHandler = (err) => {
    setIsInError(true);
  };

  useEffect(() => {
    if (url) {
      return;
    }
    const options = {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${Cookies.get('cognito-jwt')}`,
      },
    };
    fetch(source, options)
      .then((response) => {
        setUrl(response?.url);
      })
      .catch((err) => {
        setIsInError(true);
      });
  }, [source]);

  return isInError ? (
    <div>
      <ExclamationIcon /> Error loading recording
    </div>
  ) : !url ? (
    <Box mt={2}>Loading...</Box>
  ) : (
    <ReactAudioPlayer src={url} controls autoPlay={false} onError={errorHandler} />
  );
};

export default AuthenticatedPlayer;
