import Cookies from 'js-cookie';
import { toJSON } from '../queryClientConfig';

export default class BillingAddress {
  static async edit(billingAddress) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/billing_addresses/${billingAddress.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(billingAddress),
      }
    ).then(toJSON);
  }
}
