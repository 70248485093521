import { useQuery, useQueryClient } from 'react-query';

import Compliance from './Compliance';
import { queryKeyFactory } from '../../MUI/services/queryClientConfig';

export const businessProfileStatusKeys = queryKeyFactory('business_profile_status');
export const brandStatusKeys = queryKeyFactory('brand_status');
export const campaignStatusKeys = queryKeyFactory('campaign_status');
export const shakenStirStatusKeys = queryKeyFactory('shaken_stir_status');
export const voiceIntegrityStatusKeys = queryKeyFactory('voice_integrity_status');
export const cnamStatusKeys = queryKeyFactory('cnam_status');
export const complianceValidateKeys = queryKeyFactory('complianceValidate');

export const useComplianceBusinessProfileStatusShow = (orgId, options) => {
  return useQuery(
    businessProfileStatusKeys.show(orgId),
    () => Compliance.showBusinessProfileStatus(orgId),
    { ...options }
  );
};

export const useComplianceBrandStatusShow = (orgId, options) => {
  return useQuery(
    brandStatusKeys.show(orgId),
    () => Compliance.showBrandStatus(orgId),
    { ...options }
  );
};

export const useComplianceCampaignStatusShow = (orgId, options) => {
  return useQuery(
    campaignStatusKeys.show(orgId),
    () => Compliance.showCampaignStatus(orgId),
    { ...options }
  );
};

export const useComplianceTrustProductStatusShow = (orgId, productName, options) => {
  return useQuery(
    shakenStirStatusKeys.show(orgId),
    () => Compliance.showTrustProductStatus(orgId, productName),
    { ...options }
  );
};

export const useComplianceVoiceIntegrityStatusShow = (orgId, options) => {
  return useQuery(
    voiceIntegrityStatusKeys.show(orgId),
    () => Compliance.showVoiceIntegrityStatus(orgId),
    { ...options }
  );
};

export const useComplianceCnamStatusShow = (orgId, options) => {
  return useQuery(
    cnamStatusKeys.show(orgId),
    () => Compliance.showCnamStatus(orgId),
    { ...options }
  );
};

export const useComplianceValidate = (orgId, options) => {
  return useQuery(
    complianceValidateKeys.show(orgId),
    () => Compliance.validate(orgId),
    { ...options }
  );
};
