import Cookies from 'js-cookie';
import { toJSON } from '../utils/utils';

export default class Compliance {
  static async showBrandStatus(orgId) {
    return await fetch(
      `${process.env.REACT_APP_TELEPHONY_COMPLIANCE_URL}/brand_status?organization_id=${orgId}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async createBrand(brandStatus) {
    return await fetch(`${process.env.REACT_APP_TELEPHONY_COMPLIANCE_URL}/brands`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(brandStatus),
    }).then(toJSON);
  }

  static async showBusinessProfileStatus(orgId) {
    return await fetch(
      `${process.env.REACT_APP_TELEPHONY_COMPLIANCE_URL}/profile_status?organization_id=${orgId}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async createBusinessProfile(profile) {
    return await fetch(
      `${process.env.REACT_APP_TELEPHONY_COMPLIANCE_URL}/profiles`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profile),
      }
    ).then(toJSON);
  }

  static async showCampaignStatus(orgId) {
    return await fetch(
      `${process.env.REACT_APP_TELEPHONY_COMPLIANCE_URL}/campaign_status?organization_id=${orgId}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async createCampaign(campaign) {
    return await fetch(
      `${process.env.REACT_APP_TELEPHONY_COMPLIANCE_URL}/campaigns`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(campaign),
      }
    ).then(toJSON);
  }

  static async showTrustProductStatus(orgId, productName) {
    return await fetch(
      `${process.env.REACT_APP_TELEPHONY_COMPLIANCE_URL}/trust_product_status?organization_id=${orgId}&product=${productName}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async createTrustProduct(orgId, productName) {
    return await fetch(
      `${process.env.REACT_APP_TELEPHONY_COMPLIANCE_URL}/queue_trust_product?organization_id=${orgId}&product=${productName}`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async validate(orgId) {
    return await fetch(
      `${process.env.REACT_APP_TELEPHONY_COMPLIANCE_URL}/validate?organization_id=${orgId}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }
}
