import Cookies from 'js-cookie';
import { toJSON } from '../queryClientConfig';

export default class DolphinAppointmentClasses {
  static async list(filters = {}) {
    return await fetch(
      `${process.env.REACT_APP_DOLPHIN}/v1/appointment_classes?${new URLSearchParams(
        filters
      ).toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }
}
