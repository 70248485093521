import { useMutation } from 'react-query';
import Compliance from './Compliance';

export const useComplianceBrandCreate = () =>
  useMutation((params) =>
    Compliance.createBrand({
      ...params,
    })
  );

export const useComplianceBusinessProfileCreate = () =>
  useMutation((params) =>
    Compliance.createBusinessProfile({
      ...params,
    })
  );

export const useComplianceCampaignCreate = () =>
  useMutation((params) =>
    Compliance.createCampaign({
      ...params,
    })
  );

export const useComplianceTrustProductCreate = () =>
  useMutation((params) =>
    Compliance.createTrustProduct({
      ...params,
    })
  );
