import { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { LoadingButton } from '@mui/lab';
import {
  useFacebookAdsList,
  useAdsAccountsList,
  useFacebookAdPixelsList,
} from '../services/facebookAdsAccounts/queries';
import {
  useAdsAccountEdit,
  useAdsAccountDelete,
} from '../services/facebookAdsAccounts/mutations';
import { useOrg } from '../../contexts/OrgProvider';
import FacebookIcon from '../../assets/images/icons8-facebook.svg';
import { fireDialog } from '../components/Dialog';
import ClipLoader from 'react-spinners/ClipLoader';

export default function FacebookPage() {
  const {
    mutate: editAdsAccount,
    isLoading: isSaving,
    isSuccess,
  } = useAdsAccountEdit();
  const [facebookAdAccount, setFacebookAccount] = useState();
  const [facebookAdAccountId, setFacebookAccountId] = useState();
  const [facebookAdAccountName, setFacebookAccountName] = useState();
  const [pixelId, setPixelId] = useState('');
  const [actionsEnabled, setActionsEnabled] = useState(false);
  const { id: orgId } = useOrg();

  const { data: adsAccounts, isLoading } = useAdsAccountsList([
    { platform: 'meta', organization_id: orgId },
  ]);

  const {
    data: facebookAdAccounts,
    isLoading: facebookAdAccountsIsLoading,
  } = useFacebookAdsList([{ organization_id: orgId }]);

  const {
    data: facebookPixels,
    isLoading: actionsIsLoading,
    isFetching: actionsIsFetching,
    refetch,
  } = useFacebookAdPixelsList(
    [
      {
        organization_id: orgId,
        customer_id: facebookAdAccountId,
      },
    ],
    {
      enabled: actionsEnabled,
    }
  );

  useEffect(() => {
    if (
      adsAccounts &&
      Array.isArray(adsAccounts.items) &&
      adsAccounts.items.length > 0
    ) {
      setFacebookAccount(adsAccounts.items[0]);
      if (adsAccounts.items[0].account) {
        const account = JSON.parse(adsAccounts.items[0].account);
        setFacebookAccountId(account.id);
        setFacebookAccountName(account.name);
        setPixelId(account.pixelId);
        setActionsEnabled(true);
      }
    }
  }, [adsAccounts]);

  const handleDeactivate = () => {
    let id = facebookAdAccount.id;
    fireDialog((promiseProps) => DeleteAdsAccountDialog({ id, ...promiseProps }));
  };

  const handleAdAccountUpdate = (e) => {
    const account = e.target.value.split(':');
    setFacebookAccountId(account[0]);
    setFacebookAccountName(account[1]);
    if (pixelId) {
      setPixelId(null);
    }

    refetch();
  };

  const handleSave = (e) => {
    editAdsAccount({
      id: facebookAdAccount.id,
      account: JSON.stringify({
        id: facebookAdAccountId,
        name: facebookAdAccountName,
        pixelId: pixelId,
      }),
    });
  };

  const handlePixelUpdate = (e) => {
    setPixelId(e.target.value);
  };

  const buildFacebookAdsSelect = (facebookAdAccounts) => {
    let options = [];
    facebookAdAccounts.map((item) => {
      options.push(
        <ListSubheader key={`subheader-${item.id}`}>{item.name}</ListSubheader>
      );
      item.clients.map((client) => {
        options.push(
          <MenuItem key={client.id} value={`${client.id}:${client.name}`}>
            {client.name} ({client.id})
          </MenuItem>
        );
      });
    });
    return options;
  };

  const buildFacebookPixelSelect = (actions) => {
    let options = [];
    actions.map((item) => {
      options.push(
        <MenuItem key={item.id} value={`${item.id}`}>
          {item.name}({item.id})
        </MenuItem>
      );
    });
    return options;
  };

  const DeleteAdsAccountDialog = ({ isOpen, onResolve, onReject, id }) => {
    const { mutate: deleteAdsAccount, isError, isLoading } = useAdsAccountDelete();

    const handleAdsAccountDelete = () => {
      deleteAdsAccount(id, {
        onSuccess: onResolve,
      });
      setFacebookAccount(null);
    };

    return (
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Deactive Meta Ads?</DialogTitle>
        <DialogContent>
          {isError && (
            <Alert severity="error">
              There was a problem deactivating Meta Ads. Try again.
            </Alert>
          )}
          Are you sure you would like to deactivate Meta Ad?
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            onClick={handleAdsAccountDelete}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

  if (isLoading) {
    return (
      <Box>
        <SettingsCard
          sx={{
            width: '100%',
          }}
        >
          <Grid container>
            <SettingsCard.Header>Meta Ads Integration</SettingsCard.Header>
            <SettingsCard.Main sx={{ m: 5 }}>
              <Grid item align="center">
                <ClipLoader size={50} color="#34D1B6" />
              </Grid>
            </SettingsCard.Main>
          </Grid>
        </SettingsCard>
      </Box>
    );
  }

  return (
    <Box>
      <SettingsCard
        sx={{
          width: '100%',
        }}
      >
        <Grid container>
          <SettingsCard.Header>Meta Ads Integration</SettingsCard.Header>
          <Grid item xs={3} m={0.5}>
            {facebookAdAccount ? (
              <Chip
                label="Active"
                color="primary"
                style={{
                  backgroundColor: '#009900',
                  borderRadius: '4px',
                  mt: '2',
                  border: '0px solid green',
                }}
              />
            ) : (
              <Chip
                label="Inactive"
                color="primary"
                style={{
                  backgroundColor: '#dce0dd',
                  borderRadius: '4px',
                  mt: '2',
                  border: '0px solid #dce0dd',
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item xs={8}>
            <Typography sx={{ mt: 1 }}>
              Measure the ROI and effectiveness of your campaigns by integrating with
              Meta Ads. Once your direct integration with Meta Ads is active, you can
              match leads with your active campaigns, and see leads as conversions in
              Meta Ads. You'll also be able to:<br></br>&nbsp;&nbsp;• Align your
              campaign clicks with individual callers or visitors.
              <br></br>&nbsp;&nbsp; • Use conversions to see how your ad groups and
              campaigns perform.<br></br>&nbsp;&nbsp; • Determine which PPC keywords
              are most effectively driving traffic to your website.
            </Typography>
          </Grid>
          <Grid item>
            <Card
              sx={{
                minWidth: 250,
                minHeight: 100,
                display: 'flex',
                ml: 4,
              }}
              style={{ backgroundColor: '#f2f1ed' }}
              elevation={0}
            >
              <Grid item ml={2} mt={2} paddingBottom={2}>
                <Typography variant="body1" sx={{ width: 200, minWidth: '100%' }}>
                  Question about this integration? Check out here
                </Typography>
                <Link
                  target="blank"
                  href="https://sigmaleads.atlassian.net/wiki/spaces/LCS/pages/1743126530/Upload+call+conversions+to+Meta"
                >
                  Support Documentation
                </Link>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" mt={1}>
          <Grid item xs={8}>
            {facebookAdAccount ? (
              <>
                <Grid item sx={{ mt: 2 }}>
                  {isSuccess && (
                    <Alert severity="success">Meta Ads account updated</Alert>
                  )}
                  {facebookAdAccountsIsLoading && (
                    <Grid item align="center">
                      <ClipLoader size={50} color="#34D1B6" />
                    </Grid>
                  )}
                  {!facebookAdAccountsIsLoading && (
                    <>
                      <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                        <InputLabel id="demo-multiple-name-label">
                          Account
                        </InputLabel>
                        <Select
                          value={`${facebookAdAccountId}:${facebookAdAccountName}`}
                          id="grouped-select"
                          label="Grouping"
                          onChange={handleAdAccountUpdate}
                        >
                          {facebookAdAccounts &&
                            buildFacebookAdsSelect(facebookAdAccounts)}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Grid>
                {actionsIsFetching ? (
                  <Grid item align="center">
                    <ClipLoader size={50} color="#34D1B6" />
                  </Grid>
                ) : (
                  <>
                    <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                      <InputLabel>Pixel</InputLabel>
                      <Select value={`${pixelId}`} onChange={handlePixelUpdate}>
                        {facebookPixels && buildFacebookPixelSelect(facebookPixels)}
                      </Select>
                    </FormControl>
                  </>
                )}
                <Grid item sx={{ ml: 1 }}>
                  <LoadingButton
                    sx={{ mt: 1 }}
                    size="medium"
                    loading={isSaving}
                    onClick={handleSave}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </>
            ) : (
              <Typography variant="body1" sx={{ width: 200, minWidth: '100%' }}>
                <br></br>
                <Button
                  color="google"
                  sx={{
                    maxHeight: '36px',
                    py: 0,
                    pl: 0,
                    pr: 1,
                    borderRadius: 0.2,
                    mb: 2,
                    '& img': { dipslay: 'block', height: '100%' },
                    '& .MuiButton-startIcon': {
                      height: '40px',
                      m: 0,
                      p: 0,
                      ml: -0.3,
                    },
                  }}
                  href={`${process.env.REACT_APP_CALL_TRACKING}/meta/login?organization_id=${orgId}`}
                  type="submit"
                  startIcon={<img src={FacebookIcon} alt="Sign in with Meta" />}
                >
                  Sign in with Meta
                </Button>
              </Typography>
            )}
          </Grid>
          {facebookAdAccount ? (
            <Grid item mt={2}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Card
                  sx={{
                    minWidth: 250,
                    minHeight: 100,
                    display: 'flex',
                  }}
                  style={{ backgroundColor: '#f2f1ed' }}
                  elevation={0}
                >
                  <Grid item mt={2} paddingBottom={2}>
                    <Typography
                      ml={2}
                      variant="body1"
                      sx={{ width: 200, minWidth: '100%' }}
                    >
                      Deactivating this integration will clear your current settings
                      and disconnect Meta Ads.
                    </Typography>

                    <Button
                      color="inherit"
                      sx={{
                        color: '#f55e53',
                        border: '2px solid #f55e53',
                        backgroundColor: '#f2f1ed',
                        boxShadow: 'none',
                        ml: 2,
                        mt: 2,
                      }}
                      onClick={handleDeactivate}
                    >
                      Deactivate Meta Ads
                    </Button>
                  </Grid>
                </Card>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </SettingsCard>
    </Box>
  );
}
