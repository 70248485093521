import {
  filterMapStringValueToParamArray,
  filterMapArrayValueToParams,
} from '../Filters/utils';

import { eventTypeNameMap } from '../../services/events/eventsUtils';
import { formatName } from '../../services/textFormatting';

export const parseActivitiesFilterParams = (
  activitiesFilterMap,
  isMasterAccount,
  orgId
) => {
  let queryParamPairs = [];
  Object.entries(activitiesFilterMap).forEach(([filterMapKey, filterMapValue]) => {
    if (filterMapValue?.length > 0) {
      queryParamPairs.push(
        ...activitiesSerializationHandlers[filterMapKey](
          filterMapKey,
          filterMapValue
        )
      );
    }
  });

  queryParamPairs.filter((v) => v[1] != 'Invalid date');
  let tmp_params = [...queryParamPairs];
  if (activitiesFilterMap?.organization_id?.length === 0 && !isMasterAccount) {
    tmp_params.push(['organization_id[]', `eq::${orgId}`]);
  }

  return [tmp_params, queryParamPairs];
};

export const deserializeActivitesSavedSearches = ({
  savedSearches,
  accountsList,
  usersList,
  filterOperators,
  setFilterOperators,
}) => {
  const filters = [...savedSearches];
  let map = {};
  filters?.forEach((savedFilter) => {
    const filter = [...savedFilter];
    const filterMapKey = filter[0].replace('[]', '');

    if (filterMapKey === 'event_type') {
      const filterValue = filter[1];

      const newSelectedOption = {
        filterValue,
        name: eventTypeNameMap[filterValue],
      };

      if (!map['event_type']) {
        map['event_type'] = [newSelectedOption];
      } else {
        map['event_type'].push(newSelectedOption);
      }
    }
    if (filterMapKey === 'sender_type') {
      const filterValue = filter[1];

      const newSelectedOption = {
        filterValue: filterValue === 'none' ? null : filterValue,
        name: { user: 'User', automation: 'Automation' }[filterValue],
      };

      if (!map['sender_type']) {
        map['sender_type'] = [newSelectedOption];
      } else {
        map['sender_type'].push(newSelectedOption);
      }
    }
    if (filterMapKey === 'min_dttm') {
      const filterValue = filter[1];
      map['min_dttm'] = filterValue;
    }
    if (filterMapKey === 'max_dttm') {
      const filterValue = filter[1];
      map['max_dttm'] = filterValue;
    }
    if (filterMapKey === 'actor_user_id') {
      const filterValue = filter[1];
      let newSelectedOption;
      if (filterValue !== 'null') {
        const found = usersList?.find(({ id }) => id === filterValue);
        newSelectedOption = {
          filterValue,
          name: formatName(found?.first_name, found?.last_name),
        };
      } else {
        newSelectedOption = {
          filterValue,
          name: '(None)',
        };
      }

      if (!map['actor_user_id']) {
        map['actor_user_id'] = [newSelectedOption];
      } else {
        map['actor_user_id'].push(newSelectedOption);
      }
    }
    if (filterMapKey === 'organization_id') {
      let filterId = filter[1];
      if (String(filter[1]).includes('::')) {
        const filterValues = filter[1].split('::');
        setFilterOperators({
          ...filterOperators,
          organization_id: filterValues[0],
        });
        filterId = filterValues[1];
      } else {
        setFilterOperators({ ...filterOperators, organization_id: 'eq' });
      }
      const found = accountsList?.find(({ id }) => String(id) === String(filterId));
      const newSelectedOption = {
        filterValue: String(filter[1]).includes('::')
          ? filter[1]
          : `eq::${filter[1]}`,
        name: found?.name,
      };
      if (!map['organization_id']) {
        map['organization_id'] = [newSelectedOption];
      } else {
        map['organization_id'].push(newSelectedOption);
      }
    }
  });

  return map;
};

const activitiesSerializationHandlers = {
  event_type: filterMapArrayValueToParams,
  sender_type: filterMapArrayValueToParams,
  min_dttm: filterMapStringValueToParamArray,
  max_dttm: filterMapStringValueToParamArray,
  actor_user_id: filterMapArrayValueToParams,
  organization_id: filterMapArrayValueToParams,
};
