import { EmailIcon, PhoneIcon, TextIcon, ExclamationIcon } from '../../theme/icons';
import { convertDateFilterToQueryParams } from '../../components/Filters/DateFilter';
import {
  filterMapStringValueToParamArray,
  filterMapArrayValueToParams,
} from '../Filters/utils';

export const getStatusType = (task) => {
  if (task.canceled_at) return 'Canceled';
  if (task.completed_at) return 'Completed';
  return 'Open';
};

export const getAccountName = (item, accounts) => {
  const foundAccount = accounts?.find(
    ({ id }) => String(item.organization_id) === String(id)
  );
  if (foundAccount) return foundAccount.name;
  // no account exists that maps to given account id
  return null;
};

export const taskActionIconMap = {
  Email: EmailIcon,
  Call: PhoneIcon,
  Text: TextIcon,
  FollowUp: (props) => <ExclamationIcon {...props} color="secondary" />,
  ReEngage: (props) => <ExclamationIcon {...props} color="secondary" />,
};

export const taskFilterSerializeHandlers = {
  task_action_id: (filterKey, filterMapValue) => {
    const taskActionsParams = filterMapArrayValueToParams(filterKey, filterMapValue);
    return taskActionsParams;
  },
  task_sequence_id: filterMapArrayValueToParams,
  task_sequence_name: filterMapArrayValueToParams,
  source_name: filterMapArrayValueToParams,
  status: (filterKey, filterMapValue) => {
    return [[filterMapValue, true]];
  },
  lead_created_at: (k, v) => convertDateFilterToQueryParams('lead_created_at', k, v),
  completed_at_start: filterMapStringValueToParamArray,
  completed_at_end: filterMapStringValueToParamArray,
  organization_id: filterMapArrayValueToParams,
  assigned_user_id: filterMapArrayValueToParams,
  team_id: filterMapArrayValueToParams,
  lead_custom_field_values: (filterKey, filterMapValue) => {
    let customFieldFilterParams = [];
    const customFieldsIdMap = filterMapValue['customFieldsIdMap'];
    Object.entries(customFieldsIdMap).forEach(
      ([customFieldId, { operator, filterValue }]) => {
        if (filterValue?.length > 0)
          customFieldFilterParams.push([
            'lead_custom_field_values[]',
            `${customFieldId}::${operator}::${filterValue}`,
          ]);
      }
    );
    return customFieldFilterParams;
  },
};

export const parseTasksFilterParams = (tasksFilterMap, isMasterAccount, orgId) => {
  let serializedParams = [];
  Object.entries(tasksFilterMap)?.forEach(([filterMapKey, filterMapValue]) => {
    const isCustomFieldsIdMap = () => {
      if (filterMapValue?.customFieldsIdMap)
        return Object.entries(filterMapValue.customFieldsIdMap).length > 0;
      else return false;
    };

    if (filterMapValue?.length > 0 || isCustomFieldsIdMap()) {
      if (typeof taskFilterSerializeHandlers[filterMapKey] === 'function')
        serializedParams.push(
          ...taskFilterSerializeHandlers[filterMapKey](filterMapKey, filterMapValue)
        );
    }
  });

  // ad hoc handling
  const hasStatus = serializedParams?.find(
    ([key, _]) => key === 'completed' || key === 'canceled'
  );
  if (!hasStatus) serializedParams.push(['due_today', true]);
  // check length just in case
  if (tasksFilterMap.sql_operator?.length > 0) {
    const groupingSerializedParams = serializedParams.filter(([filterKey]) => {
      //TODO remove with OR ui
      if (filterKey.includes('action') || filterKey.includes('sequence'))
        return false;
      return true;
    });
    groupingSerializedParams.push(...tasksFilterMap.sql_operator);
    let tmp_params = [...groupingSerializedParams];
    if (tasksFilterMap.organization_id.length === 0 && !isMasterAccount) {
      tmp_params.push(['organization_id[]', `eq::${orgId}`]);
    }
    return [tmp_params, groupingSerializedParams];
  } else {
    let tmp_params = [...serializedParams];
    if (tasksFilterMap?.organization_id?.length === 0 && !isMasterAccount) {
      tmp_params.push(['organization_id[]', `eq::${orgId}`]);
    }
    return [tmp_params, serializedParams];
  }
};
