import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  AccordionActions,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useOrg } from '../../../../contexts/OrgProvider';
import { useFilters } from '../../../services/hooks/useFilters';
import { useUsersCurrent } from '../../../services/users/queries';
import { useSavedSearchesEdit } from '../../../services/savedSearches/mutations';
import { useSavedSearchesList } from '../../../services/savedSearches/queries';
import { useSourcesList } from '../../../services/sources/queries';
import { useLostReasonsList } from '../../../services/lostReasons/queries';
import { useOrganizationsList } from '../../../services/organizations/queries';
import { useUsersList } from '../../../services/users/queries';
import { useTeamsList } from '../../../services/teams/queries';
import { DeleteIcon, StarIcon } from '../../../theme/icons';
import {
  CreateSavedSearchDialog,
  DeleteSavedSearchDialog,
} from '../../SavedSearchDialogs';
import {
  FilterAccordion,
  FilterAccordionSummary,
  FilterOperator,
} from '../../Filters/FilterAccordion';
import { DateFilter, deserializeSavedDateFilter } from '../../Filters/DateFilter';
import { fireDialog } from '../../Dialog';
import { parseFilterParams } from '../../Filters/utils';
import { getAccountName } from '../../Filters/utils';

const FilterBar = forwardRef(({ setReportsFilterMap }, ref) => {
  useImperativeHandle(ref, () => ({
    changeFilterParams(params) {
      setFilterMap((prev) => ({
        ...prev,
        ...params,
      }));
    },
  }));

  const history = useHistory();
  const { search } = useLocation();

  const [canUseLostReasonsList, setCanUseLostReasonsList] = useState(false);
  const [canUseSourcesList, setCanUseSourcesList] = useState(false);
  const [sourceInputValue, setSourceInputValue] = useState('');
  const [sourcesOptions, setSourcesOptions] = useState([]);
  const [lostReasonOptions, setLostReasonOptions] = useState([]);
  const [causeTextFieldValueChange, setCauseTextFieldValueChange] = useState(false);
  const [selectedSavedSearchId, setSelectedSavedSearchId] = useState();
  const [canUseUsersList, setCanUseUsersList] = useState(false);
  const [teamsOptions, setTeamsOptions] = useState([]);
  const [filterOperators, setFilterOperators] = useState({});

  const parseQueryParams = () => {
    const params = new URLSearchParams(search);

    return params.entries().reduce((acc, [key, value]) => {
      acc.push([key, value]);
      return acc;
    }, []);
  };
  const [queryParams, setQueryParams] = useState([]);

  const { filterMap, setFilterMapValue, setFilterMap, clearFilterMap } = useFilters({
    initialFilters: initialReportsFilterMapValues,
  });

  const { id: orgId, isMasterAccount } = useOrg();
  const { data: currentUser } = useUsersCurrent();
  const { data: accountsList } = useOrganizationsList();
  const { data: sourcesList, isLoading: sourcesListIsLoading } = useSourcesList(
    [['name', sourceInputValue]],
    {
      enabled: canUseSourcesList,
    }
  );
  const { data: savedSearchesList } = useSavedSearchesList(
    { user_id: currentUser.id, organization_id: orgId },
    {
      resourceClass: 'reports_overview',
    }
  );
  const {
    data: lostReasonsList,
    isLoading: lostReasonsListIsLoading,
  } = useLostReasonsList([], {
    enabled: canUseLostReasonsList,
  });
  const { data: usersList } = useUsersList([], {
    enabled: canUseUsersList,
  });
  const { data: teamsList, isLoading: isLoadingTeamsList } = useTeamsList();

  const { mutate: editSavedSearch } = useSavedSearchesEdit();
  const selectedAccountsSet = useSelectedAccountsSet(filterMap, accountsList);
  const createFilterMapFromSavedFilters = (filters) => {
    let map = {};

    filters?.forEach((filter) => {
      const filterMapKey = filter[0].replace('[]', '');
      if (filterMapKey === 'source_name') {
        const newSelectedOption = {
          filterValue: filter[1],
          name: filter[1],
        };
        if (!map['source_name']) {
          map['source_name'] = [newSelectedOption];
        } else {
          map['source_name'].push(newSelectedOption);
        }
      }
      if (filterMapKey === 'date_range_selector') {
        map['date_range'] = deserializeSavedDateFilter(filters);
      }
      if (filterMapKey === 'lost_reason_name') {
        const newSelectedOption = {
          filterValue: filter[1],
          name: filter[1],
        };
        if (!map['lost_reason_name']) {
          map['lost_reason_name'] = [newSelectedOption];
        } else {
          map['lost_reason_name'].push(newSelectedOption);
        }
      }
      if (filterMapKey === 'team_id') {
        const filterId = filter[1];
        const found = teamsList?.find(({ id }) => String(id) === String(filterId));
        const newSelectedOption = {
          filterValue: filterId,
          name: found?.name,
        };
        if (!map['team_id']) {
          map['team_id'] = [newSelectedOption];
        } else {
          map['team_id'].push(newSelectedOption);
        }
      }
      if (filterMapKey === 'organization_id') {
        let filterId = filter[1];
        if (String(filter[1]).includes('::')) {
          const filterValues = filter[1].split('::');
          setFilterOperators({
            ...filterOperators,
            organization_id: filterValues[0],
          });
          filterId = filterValues[1];
        } else {
          setFilterOperators({ ...filterOperators, organization_id: 'eq' });
        }
        const found = accountsList?.find(
          ({ id }) => String(id) === String(filterId)
        );
        const newSelectedOption = {
          filterValue: String(filter[1]).includes('::')
            ? filter[1]
            : `eq::${filter[1]}`,
          name: found?.name,
        };
        if (!map['organization_id']) {
          map['organization_id'] = [newSelectedOption];
        } else {
          map['organization_id'].push(newSelectedOption);
        }
      }
    });
    return map;
  };

  const loadSavedSearch = (savedSearch, doClearFilterMap) => {
    if (doClearFilterMap) clearFilterMap();
    const savedFiltersMap = createFilterMapFromSavedFilters(savedSearch);

    Object.entries(savedFiltersMap).forEach(([filterMapKey, filterMapValue]) =>
      setFilterMapValue(filterMapKey, filterMapValue)
    );
    setCauseTextFieldValueChange((prev) => !prev);
  };

  const handleClearFilterMap = () => {
    history.push('/reports/overview');
    clearFilterMap();
    setSelectedSavedSearchId(null);
    setCauseTextFieldValueChange((prev) => !prev);
  };

  useEffect(() => {
    if (!lostReasonsList) return;

    let uniq = [...new Set(lostReasonsList.map((t) => t.name))];
    let options = uniq
      .sort()
      .filter((t) => t.trim() != '')
      .map((t) => {
        return { filterValue: t, name: t };
      });
    setLostReasonOptions(options);
  }, [lostReasonsList]);

  useEffect(() => {
    if (!sourcesList) {
      return;
    }
    let uniq = [...new Set(sourcesList.map((t) => t.name))];
    let options = uniq
      .sort()
      .filter((t) => t.trim() != '')
      .map((t) => {
        return { filterValue: t, name: t };
      });
    setSourcesOptions(options);
  }, [sourcesList]);

  useEffect(() => {
    const defaultSearch = savedSearchesList?.find(({ is_default }) => is_default);
    if (!!defaultSearch) {
      if (!selectedSavedSearchId) setSelectedSavedSearchId(defaultSearch?.id);

      loadSavedSearch(defaultSearch?.data, false);
    }
  }, [savedSearchesList, accountsList]);

  useEffect(() => {
    setReportsFilterMap(filterMap);
  }, [filterMap]);

  useEffect(() => {
    if (sourceInputValue.length > 2) {
      setCanUseSourcesList(true);
    } else {
      setCanUseSourcesList(false);
      setSourcesOptions([]);
    }
  }, [sourceInputValue]);

  useEffect(() => {
    const teamsFilterOptions = teamsList
      ?.reduce((acc, team) => {
        if (
          selectedAccountsSet?.has(String(team.organization_id)) ||
          !selectedAccountsSet
        ) {
          const teamAccountName = getAccountName(team, accountsList);
          if (teamAccountName) {
            acc.push({
              filterValue: team.id,
              account: teamAccountName,
              name: team.name,
            });
          }
        }
        return acc;
      }, [])
      .sort((a, b) => String(a.account).localeCompare(String(b.account)));

    setTeamsOptions(teamsFilterOptions ?? []);
  }, [selectedAccountsSet, teamsList, accountsList]);

  useEffect(() => {
    if (queryParams.length > 0) {
      const queryParamsFiltersMap = createFilterMapFromSavedFilters(queryParams);

      Object.entries(
        queryParamsFiltersMap
      ).forEach(([filterMapKey, filterMapValue]) =>
        setFilterMapValue(filterMapKey, filterMapValue)
      );
      setCauseTextFieldValueChange((prev) => !prev);
    }
  }, [queryParams, accountsList]);

  useEffect(() => {
    if (typeof search === 'string' && search.length > 0) {
      setQueryParams(parseQueryParams());
    }
  }, [search]);

  return (
    <Box
      flexDirection="column"
      width={250}
      sx={{
        display: 'flex',
        border: ({ palette }) => `1px solid ${palette.grey[300]}`,
        borderRadius: '4px',
      }}
    >
      <Box
        sx={{
          borderRadius: 'inherit',
          minHeight: '39px',
          width: '250px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          columnGap: 1,
          px: '10px',
          backgroundColor: ({ palette }) => palette.grey[100],
          borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}`,
        }}
      >
        <Typography fontSize={14} fontWeight={500} sx={{ flexBasis: '100%' }}>
          Filters
        </Typography>
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            backgroundColor: ({ palette }) => palette.common.white,
            minWidth: '40px',
            height: '25px',
            p: 0.5,
          }}
          onClick={() => {
            const [_, filterParams] = parseFilterParams(
              filterMap,
              isMasterAccount,
              orgId
            );

            fireDialog((promiseProps) =>
              CreateSavedSearchDialog({
                ...promiseProps,
                setSelectedSavedSearchId,
                resourceClass: 'reports_overview',
                filterParams,
              })
            );
          }}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            backgroundColor: ({ palette }) => palette.common.white,
            minWidth: '40px',
            height: '25px',
            p: 0.5,
          }}
          onClick={handleClearFilterMap}
        >
          Clear
        </Button>
      </Box>
      <Box overflow="auto">
        <FilterAccordion defaultExpanded>
          <FilterAccordionSummary>Saved Searches</FilterAccordionSummary>
          <AccordionActions sx={{ alignItems: 'flex-start' }}>
            <Box width="100%" display="flex" flexDirection="column">
              {savedSearchesList?.length > 0 ? (
                savedSearchesList.map((savedSearch) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: 0.5,
                      ':hover': {
                        cursor: 'pointer',
                      },
                    }}
                    key={savedSearch.id}
                  >
                    <Box
                      flexBasis="100%"
                      onClick={() => {
                        setSelectedSavedSearchId(savedSearch.id);
                        loadSavedSearch(savedSearch.data, true);
                      }}
                    >
                      <Typography
                        fontSize={14}
                        fontWeight={
                          selectedSavedSearchId == savedSearch.id ? '600' : ''
                        }
                      >
                        {savedSearch.name}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() =>
                        editSavedSearch({
                          ...savedSearch,
                          is_default: !savedSearch.is_default,
                        })
                      }
                      size="small"
                      sx={{
                        padding: 0,
                        color: ({ palette }) =>
                          savedSearch.is_default
                            ? palette.warning.light
                            : palette.grey[300],
                      }}
                    >
                      <StarIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        fireDialog((promiseProps) =>
                          DeleteSavedSearchDialog({
                            ...promiseProps,
                            savedSearchId: savedSearch.id,
                          })
                        )
                      }
                      sx={{ padding: 0 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))
              ) : (
                <Typography fontSize={14}>No saved searches</Typography>
              )}
            </Box>
          </AccordionActions>
        </FilterAccordion>
        <DateFilter
          setSelectedSavedSearchId={setSelectedSavedSearchId}
          setFilterMapValue={setFilterMapValue}
          filterMap={filterMap}
          fieldName="date_range"
          title="Date Range"
          isPermanentFilter
          defaultExpanded
        />
        <FilterAccordion>
          <FilterAccordionSummary>
            Source
            {filterMap.source_name.length > 0 && (
              <Chip
                onDelete={() => {
                  setSelectedSavedSearchId(null);
                  setFilterMapValue('source_name', []);
                }}
                label={filterMap.source_name.length}
              />
            )}
          </FilterAccordionSummary>
          <AccordionActions>
            <Autocomplete
              autoComplete
              multiple
              sx={{ width: '100%' }}
              onChange={(_, value) => {
                setSelectedSavedSearchId(null);
                setFilterMapValue('source_name', value);
              }}
              onClose={() => {
                setCanUseSourcesList(false);
                setSourcesOptions([]);
              }}
              onInputChange={(_, v) => setSourceInputValue(v)}
              inputValue={sourceInputValue}
              value={filterMap.source_name ?? []}
              getOptionLabel={(option) => option.name}
              options={sourcesOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Source"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {sourcesListIsLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.filterValue === value.filterValue
              }
            />
          </AccordionActions>
        </FilterAccordion>
        <FilterAccordion
          onChange={(_, expanded) => {
            setCanUseLostReasonsList(expanded);
          }}
        >
          <FilterAccordionSummary>
            Lost Reason
            {filterMap.lost_reason_name.length > 0 && (
              <Chip
                onDelete={() => {
                  setSelectedSavedSearchId(null);
                  setFilterMapValue('lost_reason_name', []);
                }}
                label={filterMap.lost_reason_name.length}
              />
            )}
          </FilterAccordionSummary>
          <AccordionActions>
            <Autocomplete
              autoComplete
              multiple
              sx={{ width: '100%' }}
              onChange={(_, value) => {
                setSelectedSavedSearchId(null);
                setFilterMapValue('lost_reason_name', value);
              }}
              value={filterMap.lost_reason_name}
              options={lostReasonOptions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Lost Reason"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {lostReasonsListIsLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.filterValue === value.filterValue
              }
            />
          </AccordionActions>
        </FilterAccordion>
        <FilterAccordion
          onChange={(_, expanded) => {
            setCanUseUsersList(expanded);
          }}
        >
          <FilterAccordionSummary>
            Team
            {filterMap.team_id.length > 0 && (
              <Chip
                onDelete={() => {
                  setSelectedSavedSearchId(null);
                  setFilterMapValue('team_id', []);
                }}
                label={filterMap.team_id.length}
              />
            )}
          </FilterAccordionSummary>
          <AccordionActions>
            <Autocomplete
              autoComplete
              multiple
              sx={{ width: '100%' }}
              value={filterMap.team_id}
              onChange={(_, value) => {
                setSelectedSavedSearchId(null);
                setFilterMapValue('team_id', value);
              }}
              options={teamsOptions}
              groupBy={(option) => option.account}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Team"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingTeamsList ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.filterValue === value.filterValue
              }
            />
          </AccordionActions>
        </FilterAccordion>
        {isMasterAccount && (
          <FilterAccordion>
            <FilterAccordionSummary>
              Account
              {filterMap.organization_id.length > 0 && (
                <Chip
                  onDelete={() => {
                    setSelectedSavedSearchId(null);
                    setFilterMapValue('organization_id', []);
                  }}
                  label={filterMap.organization_id.length}
                />
              )}
            </FilterAccordionSummary>
            <AccordionActions>
              <FilterOperator
                filterName={'organization_id'}
                filterOperators={filterOperators}
                setFilterOperators={setFilterOperators}
                setFilterMapValue={setFilterMapValue}
                setSelectedSavedSearchId={setSelectedSavedSearchId}
              >
                <Autocomplete
                  autoComplete
                  multiple
                  sx={{ width: '100%' }}
                  value={filterMap.organization_id}
                  onChange={(_, value) => {
                    setSelectedSavedSearchId(null);
                    setFilterMapValue('organization_id', value);
                  }}
                  options={
                    accountsList?.map(({ id, name }) => ({
                      filterValue: `${
                        filterOperators.organization_id || 'eq'
                      }::${id}`,
                      name,
                    })) ?? []
                  }
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label="Account" />}
                  isOptionEqualToValue={(option, value) =>
                    option.filterValue === value.filterValue
                  }
                />
              </FilterOperator>
            </AccordionActions>
          </FilterAccordion>
        )}
      </Box>
    </Box>
  );
});

const useSelectedAccountsSet = (filterMap, accountsList) => {
  const [selectedAccountsSet, setSelectedAccountsSet] = useState(null);

  useEffect(() => {
    if (filterMap.organization_id?.length > 0) {
      let set = new Set();

      if (String(filterMap.organization_id[0].filterValue).startsWith('eq::')) {
        filterMap.organization_id?.forEach(({ filterValue }) =>
          set.add(String(filterValue.split('::')[1]))
        );
      } else if (
        String(filterMap.organization_id[0].filterValue).startsWith('not_eq::')
      ) {
        const excludedIds = filterMap.organization_id.map(
          ({ filterValue }) => filterValue.split('::')[1]
        );
        accountsList?.forEach(({ id }) => {
          if (!excludedIds.includes(String(id))) {
            set.add(String(id));
          }
        });
      } else {
        filterMap.organization_id?.forEach(({ filterValue }) =>
          set.add(String(filterValue))
        );
      }

      setSelectedAccountsSet(set);
    } else {
      setSelectedAccountsSet(null);
    }
  }, [filterMap]);

  return selectedAccountsSet;
};

export const initialReportsFilterMapValues = {
  date_range: [{ filterValue: '9' }],
  source_name: [],
  lost_reason_name: [],
  team_id: [],
  organization_id: [],
};

export default FilterBar;
