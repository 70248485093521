import { useState } from 'react';
import { useOrganizationDefaultsList } from '../services/organizationDefaults/queries';
import {
  useOrgDefaultEdit,
  useOrgDefaultCreate,
} from '../services/organizationDefaults/mutations';
import { useOrg } from '../../contexts/OrgProvider';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { LoadingButton, TabContext, TabList } from '@mui/lab';
import HookForm from '../components/HookForm';
import { useConditionalUseFormProps } from '../components/HookForm/hooks';
import {
  createYupResolver,
  LEGAL_BUSINESS_NAME,
  BUSINESS_TYPE,
  INDUSTRY,
  BUSINESS_REGISTRATION_ID_TYPE,
  BUSINESS_REGISTRATION_ID,
  REGION_OF_OPERATIONS,
  WEBSITE,
  EMPLOYEE_COUNT,
  AVG_DAILY_CALL_VOLUME,
  VOICE_INTEGRITY_USE_CASE,
  CNAM_DISPLAY_NAME,
} from '../components/HookForm/yupValidations';
import {
  Alert,
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Tab,
  TextField,
} from '@mui/material';
import { SaveIcon } from '../theme/icons';
import {
  useOrganizationsShow,
  useBillingAddress,
} from '../services/organizations/queries';
import { useOrganizationsEdit } from '../services/organizations/mutations';
import { useBillingAddressEdit } from '../services/billingAddresses/mutations';

const BUSINESS_TYPES = [
  { value: 'sole_proprietorship', label: 'Sole Proprietorship' },
  { value: 'partnership', label: 'Partnership' },
  { value: 'limited_liability_corporation', label: 'Limited Liability Corporation' },
  { value: 'co_operative', label: 'Co Operative' },
  { value: 'non_profit_corporation', label: 'Non Profit Corporation' },
  { value: 'corporation', label: 'Corporation' },
];

const INDUSTRIES = [
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'financial', label: 'Financial' },
  { value: 'real_estate', label: 'Real Estate' },
  { value: 'technology', label: 'Technology' },
];

const VOICE_INTEGRITY_USE_CASES = [
  { value: 'call_tracking', label: 'Call Tracking' },
  { value: 'appointment_scheduling', label: 'Appointment Scheduling' },
  { value: 'lead_management', label: 'Lead Management' },
];

const REGIONS_OF_OPERATIONS = [{ value: 'usa_and_canada', label: 'USA and Canada' }];

const BUSINESS_REGISTRATION_ID_TYPES = [{ value: 'ein', label: 'EIN' }];

const AccountSettings = () => {
  const { id: orgId } = useOrg();
  const [url, setUrl] = useState('');
  const {
    mutate: editAccount,
    isError: isErrorEditingAccount,
    isEditing: isEditingAccount,
    isSuccess,
  } = useOrganizationsEdit();
  const { data: account, isError } = useOrganizationsShow(orgId, {
    onSuccess: (data) => {
      setUrl(data.greeting_audio_url);
    },
  });

  const onSubmit = () => {
    editAccount({
      id: account.id,
      greeting_audio_url: url,
    });
  };

  return (
    <Grid container direction="column" rowSpacing={2} sx={{ width: 500 }}>
      {isError && (
        <Alert severity="error">
          There was a problem loading your account. Try again.
        </Alert>
      )}
      {isErrorEditingAccount && (
        <Alert severity="error" onClose={() => reset()}>
          There was an error saving your changes. Please try again.
        </Alert>
      )}
      {isSuccess && <Alert severity="success">Your changes were saved.</Alert>}
      <Grid item>
        <h6>Active Call Notifications</h6>
      </Grid>
      <Grid item>
        <TextField
          id="greeting-audio-text"
          label="Whisper Greeting Override"
          value={url}
          onChange={() => setUrl(event.target.value)}
          size="small"
        />
      </Grid>
      <Grid item>
        <LoadingButton
          type="submit"
          onClick={onSubmit}
          loading={isEditingAccount}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          sx={{ maxWidth: 'max-content' }}
        >
          Save
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

const AccountPage = () => {
  const [currentTab, setCurrentTab] = useState('Defaults');
  const handleChangeTab = (_, tabName) => {
    setCurrentTab(tabName);
  };

  const { id: orgId } = useOrg();

  const { data: orgDefaults, isError } = useOrganizationDefaultsList(
    [['organization_id[]', orgId]],
    {}
  );

  const {
    data: billingAddresses,
    isError: isBillingAddressesError,
  } = useBillingAddress(orgId);

  const {
    mutate: editOrgDefault,
    isLoading: isEditingDefaults,
    isError: isEditError,
    isSuccess,
    reset,
  } = useOrgDefaultEdit();

  const {
    mutate: editOrgBillingAddress,
    isLoading: isEditingBillingAddress,
    isError: isEditingBillingAddressError,
    isSuccess: isEditingBillingAddressSuccess,
    reset: resetBillingAddress,
  } = useBillingAddressEdit();

  const { mutate: createOrgDefault } = useOrgDefaultCreate();

  const onAccountDefaultsSubmit = (data) => {
    const defaults = [
      'opportunity_value_cents',
      'required_phone',
      'required_email',
      'required_source',
      'required_assigned_user',
      'auto_follow_up_schedule',
      'auto_follow_up_days',
      'reengagement_attempts',
      'auto_close_appointments',
    ];

    defaults.forEach((orgDefault) => {
      let value = data[orgDefault];
      let originalValue = getOrgDefaultValue(orgDefault);
      const id = getOrgDefaultId(orgDefault);

      if (orgDefault == 'opportunity_value_cents') {
        value = parseInt(value.replace(/,/g, '')) * 100;
      }
      if (value.toString() !== originalValue) {
        if (id === undefined || id === null) {
          createOrgDefault({
            organization_id: orgId,
            key: orgDefault,
            value: value.toString(),
          });
        } else {
          editOrgDefault({
            id: id,
            value: value.toString(),
          });
        }
      }
    });
  };

  const onBillingAddressSubmit = (data) => {
    editOrgBillingAddress({
      id: billingAddresses[0].id,
      legal_business_name: data[LEGAL_BUSINESS_NAME],
      business_type: data[BUSINESS_TYPE],
      industry: data[INDUSTRY],
      business_registration_id_type: data[BUSINESS_REGISTRATION_ID_TYPE],
      business_registration_id: data[BUSINESS_REGISTRATION_ID],
      regions_of_operations: data[REGION_OF_OPERATIONS],
      website: data[WEBSITE],
      employee_count: data[EMPLOYEE_COUNT],
      avg_daily_call_volume: data[AVG_DAILY_CALL_VOLUME],
      voice_integrity_use_case: data[VOICE_INTEGRITY_USE_CASE],
      cnam_display_name: data[CNAM_DISPLAY_NAME],
    });
  };

  const getOrgDefaultId = (key) => {
    try {
      return orgDefaults?.find((x) => x['key'] === key).id;
    } catch (error) {
      return null;
    }
  };

  const getOrgDefaultValue = (key) => {
    try {
      return orgDefaults?.find((x) => x['key'] === key).value;
    } catch (error) {
      return null;
    }
  };

  const getOrgValue = (key, defaultValue) => {
    const found = orgDefaults?.find((x) => x['key'] === key);
    if (found) {
      if (found.value === 'true') return true;
      if (found.value === 'false') return false;
      return found.value.toString();
    }
    return defaultValue;
  };

  const getBillingAddressValue = (key, defaultValue) => {
    if (!billingAddresses?.length) return defaultValue;

    const found = billingAddresses[0][key];
    if (found) {
      if (found === 'true') return true;
      if (found === 'false') return false;
      return found?.toString();
    }
    return defaultValue;
  };

  const parseCentsToDollars = (cents) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
    }).format(parseInt(cents) / 100);
  };

  const orgDefaultValues = {
    ['opportunity_value_cents']: parseCentsToDollars(
      getOrgValue('opportunity_value_cents', '300000')
    ),
    ['required_phone']: getOrgValue('required_phone', false),
    ['required_email']: getOrgValue('required_email', false),
    ['required_source']: getOrgValue('required_source', false),
    ['required_assigned_user']: getOrgValue('required_assigned_user', false),
    ['auto_follow_up_schedule']: getOrgValue('auto_follow_up_schedule', false),
    ['auto_follow_up_days']: getOrgValue('auto_follow_up_days', 3),
    ['reengagement_attempts']: getOrgValue('reengagement_attempts', 3),
    ['auto_close_appointments']: getOrgValue('auto_close_appointments', false),
  };

  const billingAddressValues = {
    [LEGAL_BUSINESS_NAME]: getBillingAddressValue('legal_business_name', ''),
    [BUSINESS_TYPE]: getBillingAddressValue('business_type', ''),
    [INDUSTRY]: getBillingAddressValue('industry', ''),
    [BUSINESS_REGISTRATION_ID_TYPE]: getBillingAddressValue(
      'business_registration_id_type',
      ''
    ),
    [BUSINESS_REGISTRATION_ID]: getBillingAddressValue(
      'business_registration_id',
      ''
    ),
    [REGION_OF_OPERATIONS]: getBillingAddressValue('regions_of_operations', ''),
    [WEBSITE]: getBillingAddressValue('website', ''),
    [EMPLOYEE_COUNT]: getBillingAddressValue('employee_count', 0),
    [AVG_DAILY_CALL_VOLUME]: getBillingAddressValue('avg_daily_call_volume', 0),
    [VOICE_INTEGRITY_USE_CASE]: getBillingAddressValue(
      'voice_integrity_use_case',
      ''
    ),
    [CNAM_DISPLAY_NAME]: getBillingAddressValue('cnam_display_name', ''),
  };

  const useOrgDefaultsFormProps = useConditionalUseFormProps(
    {
      defaultValues: orgDefaultValues,
      resolver: createYupResolver(orgDefaultValues),
    },
    !!orgDefaults
  );

  const useBillingAddressFormProps = useConditionalUseFormProps(
    {
      defaultValues: billingAddressValues,
      resolver: createYupResolver(billingAddressValues),
    },
    !!billingAddresses
  );

  return (
    <Box sx={{ height: '100%' }}>
      <SettingsCard
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SettingsCard.Header>Account</SettingsCard.Header>
        <TabContext value={currentTab}>
          <TabList onChange={handleChangeTab}>
            <Tab label="Defaults" value="Defaults" />
            <Tab label="Profile" value="Profile" />
          </TabList>
        </TabContext>
        <SettingsCard.Main sx={{ flex: 1 }}>
          {currentTab === 'Defaults' && (
            <>
              {isError && (
                <Alert severity="error">
                  There was a problem loading your account. Try again.
                </Alert>
              )}
              {isEditError && (
                <Alert severity="error" onClose={() => reset()}>
                  There was an error saving your changes. Please try again.
                </Alert>
              )}
              {isSuccess && (
                <Alert severity="success">Your changes were saved.</Alert>
              )}
              <HookForm
                onSubmit={onAccountDefaultsSubmit}
                useFormProps={useOrgDefaultsFormProps}
              >
                <Grid
                  container
                  direction="column"
                  rowSpacing={2}
                  sx={{ width: 500, mb: 4 }}
                >
                  <Grid item>
                    <h6>Lead value</h6>
                  </Grid>
                  <Grid item>
                    <HookForm.TextField
                      name="opportunity_value_cents"
                      label="Default Lead Value"
                    />
                  </Grid>
                  <Grid item>
                    <h6>Required fields</h6>
                  </Grid>
                  <Grid item>
                    <InputLabel shrink={true}>
                      Require certain fields when users add a lead through the add
                      lead button. These rules are not followed for leads created by
                      integrations.
                    </InputLabel>
                    <HookForm.Switch name="required_phone" label="Require phone" />
                  </Grid>
                  <Grid item>
                    <HookForm.Switch name="required_email" label="Require email" />
                  </Grid>
                  <Grid item>
                    <HookForm.Switch name="required_source" label="Require lead" />
                  </Grid>
                  <Grid item>
                    <HookForm.Switch
                      name="required_assigned_user"
                      label="Require assigned user"
                    />
                  </Grid>
                  <Grid item>
                    <h6>Re-engagement Tasks</h6>
                  </Grid>
                  <Grid item>
                    <InputLabel shrink={true}>
                      Automatically create re-engagement tasks when a user has
                      responded but then stops responding.
                    </InputLabel>
                    <HookForm.Switch
                      name="auto_follow_up_schedule"
                      label="Automatically create re-engagement for lack of response"
                    />
                  </Grid>

                  <Grid item>
                    <HookForm.TextField
                      name="reengagement_attempts"
                      label="Re-Engagement Attempts"
                    />
                  </Grid>
                  <Grid item>
                    <HookForm.TextField
                      name="auto_follow_up_days"
                      label="Days between"
                    />
                  </Grid>
                  <Grid item>
                    <h6>Scheduling Automations</h6>
                  </Grid>
                  <Grid item>
                    <HookForm.Switch
                      name="auto_close_appointments"
                      label="Automatically Mark Won if Scheduled"
                    />
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      type="submit"
                      loading={isEditingDefaults}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      sx={{ maxWidth: 'max-content' }}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Grid>
              </HookForm>
              <AccountSettings />
            </>
          )}
          {currentTab === 'Profile' && (
            <>
              {isBillingAddressesError && (
                <Alert severity="error">
                  There was a problem loading your billing addresses. Try again.
                </Alert>
              )}
              {isEditingBillingAddressError && (
                <Alert severity="error" onClose={() => reset()}>
                  There was an error saving your changes. Please try again.
                </Alert>
              )}
              {isEditingBillingAddressSuccess && (
                <Alert severity="success">Your changes were saved.</Alert>
              )}
              {billingAddresses && billingAddresses.length > 0 && (
                <HookForm
                  onSubmit={onBillingAddressSubmit}
                  useFormProps={useBillingAddressFormProps}
                >
                  <Box
                    sx={{
                      display: 'grid',
                      direction: 'column',
                      width: '250px',
                      rowGap: 3,
                    }}
                  >
                    <HookForm.TextField
                      name={LEGAL_BUSINESS_NAME}
                      label="Legal Business Name"
                    />
                    <HookForm.Select name={BUSINESS_TYPE} label="Business Type">
                      {BUSINESS_TYPES.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </HookForm.Select>
                    <HookForm.Select name={INDUSTRY} label="Industry">
                      {INDUSTRIES.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </HookForm.Select>
                    <HookForm.Select
                      name={BUSINESS_REGISTRATION_ID_TYPE}
                      label="Business Registration ID Type"
                    >
                      {BUSINESS_REGISTRATION_ID_TYPES.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </HookForm.Select>
                    <HookForm.TextField
                      name={BUSINESS_REGISTRATION_ID}
                      label="Business Registration ID"
                    />
                    <HookForm.Select
                      name={REGION_OF_OPERATIONS}
                      label="Regions of Operations"
                    >
                      {REGIONS_OF_OPERATIONS.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </HookForm.Select>
                    <HookForm.TextField name={WEBSITE} label="Website" />
                    <HookForm.TextField
                      name={EMPLOYEE_COUNT}
                      label="Employee Count"
                      type="number"
                    />
                    <HookForm.TextField
                      name={AVG_DAILY_CALL_VOLUME}
                      label="Average Daily Call Volume"
                      type="number"
                    />
                    <HookForm.Select
                      name={VOICE_INTEGRITY_USE_CASE}
                      label="Outbound Calls Use Case"
                    >
                      {VOICE_INTEGRITY_USE_CASES.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </HookForm.Select>
                    <HookForm.TextField name={CNAM_DISPLAY_NAME} label="Caller ID" />
                    <LoadingButton
                      type="submit"
                      loading={isEditingBillingAddress}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      sx={{ maxWidth: 'max-content' }}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </HookForm>
              )}
            </>
          )}
        </SettingsCard.Main>
      </SettingsCard>
    </Box>
  );
};

export default AccountPage;
