import { useQuery } from 'react-query';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';
import AdsAccounts from './AdsAccount';
export const adsAccountsQueryKeys = {
  ...queryKeyFactory('ads_accounts'),
};
export const facebookAdsQueryKeys = {
  ...queryKeyFactory('facebook_ads'),
};
export const facebookAdPixelsKeys = {
  ...queryKeyFactory('facebook_ad_pixels'),
};

export const useAdsAccountsList = (filters, options) => {
  const mergedFilters = Object.assign({}, ...filters);

  return useQuery(
    adsAccountsQueryKeys.list(mergedFilters),
    () => AdsAccounts.list(mergedFilters),
    {
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

export const useFacebookAdsList = (filters, options) => {
  const mergedFilters = Object.assign({}, ...filters);

  return useQuery(
    facebookAdsQueryKeys.list(mergedFilters),
    () => AdsAccounts.listFacebookAdAccounts(mergedFilters),
    {
      staleTime: 0,
      ...options,
    }
  );
};

export const useFacebookAdPixelsList = (filters, options) => {
  const mergedFilters = Object.assign({}, ...filters);

  return useQuery(
    facebookAdPixelsKeys.list(mergedFilters),
    () => AdsAccounts.listActions(mergedFilters),
    {
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};
