import { Alert, Box, Grid, Typography } from '@mui/material';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  useComplianceBrandStatusShow,
  useComplianceBusinessProfileStatusShow,
  useComplianceCampaignStatusShow,
  useComplianceTrustProductStatusShow,
  useComplianceValidate,
} from '../../../../api/compliances/queries';
import {
  useComplianceBrandCreate,
  useComplianceBusinessProfileCreate,
  useComplianceCampaignCreate,
  useComplianceTrustProductCreate,
} from '../../../../api/compliances/mutations';
import { SettingsCard } from '../../../pages/_settingsPagesComponents/SettingsCard';
import { ValidateIcon, ValidateOutlineIcon } from '../../../theme/icons';
import SubmissionCard from './SubmissionCard';

const TRUST_PRODUCTS = {
  SHAKEN_STIR: 'shaken_stir',
  VOICE_INTEGRITY: 'voice_integrity',
  CNAM: 'cnam',
};

const TWILIO_STATUSES = {
  APPROVED: 'twilio-approved',
  REVIEW: 'pending-review',
  DRAFT: 'draft',
};

export default function Compliance({ orgId }) {
  const {
    data: validationData,
    isFetching: isValidationDataFetching,
  } = useComplianceValidate(orgId);
  const {
    data: brandData,
    isFetching: isBrandDataFetching,
  } = useComplianceBrandStatusShow(orgId);
  const {
    data: businessProfileData,
    isFetching: isBusinessProfileDataFetching,
  } = useComplianceBusinessProfileStatusShow(orgId);
  const {
    data: campaignData,
    isFetching: isCampaignDataFetching,
  } = useComplianceCampaignStatusShow(orgId);
  const {
    data: shakenStirData,
    isFetching: isShakenStirDataFetching,
  } = useComplianceTrustProductStatusShow(orgId, TRUST_PRODUCTS.SHAKEN_STIR);
  const {
    data: voiceIntegrityData,
    isFetching: isVoiceIntegrityDataFetching,
  } = useComplianceTrustProductStatusShow(orgId, TRUST_PRODUCTS.VOICE_INTEGRITY);
  const {
    data: cnamData,
    isFetching: isCnamDataFetching,
  } = useComplianceTrustProductStatusShow(orgId, TRUST_PRODUCTS.CNAM);

  const {
    mutate: createBrand,
    isLoading: isCreateBrandLoading,
    isSuccess: isCreateBrandSuccess,
  } = useComplianceBrandCreate();
  const {
    mutate: createBusinessProfile,
    isLoading: isCreateBusinessProfileLoading,
    isSuccess: isCreateBusinessProfileSuccess,
  } = useComplianceBusinessProfileCreate();
  const {
    mutate: createCampaign,
    isLoading: isCreateCampaignLoading,
    isSuccess: isCreateCampaignSuccess,
  } = useComplianceCampaignCreate();
  const {
    mutate: createShakenStir,
    isLoading: isCreateShakenStirLoading,
    isSuccess: isCreateShakenStirSuccess,
  } = useComplianceTrustProductCreate(orgId, TRUST_PRODUCTS.SHAKEN_STIR);
  const {
    mutate: createVoiceIntegrity,
    isLoading: isCreateVoiceIntegrityLoading,
    isSuccess: isCreateVoiceIntegritySuccess,
  } = useComplianceTrustProductCreate(orgId, TRUST_PRODUCTS.VOICE_INTEGRITY);
  const {
    mutate: createCnam,
    isLoading: isCreateCnamLoading,
    isSuccess: isCreateCnamSuccess,
  } = useComplianceTrustProductCreate(orgId, TRUST_PRODUCTS.CNAM);

  return (
    <Grid container direction="column" rowGap={2}>
      <Typography variant="h6">Compliance</Typography>
      <Grid item>
        <SettingsCard>
          <Box sx={{ py: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isValidationDataFetching ? (
                <ClipLoader size={25} color="#34D1B6" />
              ) : (
                <>
                  {validationData?.is_valid ? (
                    <ValidateIcon
                      sx={{
                        color: ({ palette }) => palette.primary.main,
                      }}
                    />
                  ) : (
                    <ValidateOutlineIcon
                      sx={{
                        color: ({ palette }) => palette.grey['500'],
                      }}
                    />
                  )}
                </>
              )}
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                Provide Business Profile and Authorized Representative information
              </Typography>
            </Box>
          </Box>
          {!validationData?.is_valid && (
            <Alert severity="warning" sx={{ ml: 1 }}>
              {validationData?.message}
            </Alert>
          )}
        </SettingsCard>
      </Grid>
      <SubmissionCard
        data={businessProfileData}
        isLoading={isBusinessProfileDataFetching || isCreateBusinessProfileLoading}
        isSuccess={isCreateBusinessProfileSuccess}
        text="Submit Business Profile"
        createFunction={createBusinessProfile}
        isDisbled={
          businessProfileData?.status === TWILIO_STATUSES.APPROVED ||
          businessProfileData?.status === TWILIO_STATUSES.REVIEW ||
          isCreateBusinessProfileSuccess
        }
        notice={
          businessProfileData?.status === TWILIO_STATUSES.REVIEW
            ? 'Pending review'
            : ''
        }
      />
      <SubmissionCard
        data={brandData}
        isLoading={isBrandDataFetching || isCreateBrandLoading}
        isSuccess={isCreateBrandSuccess}
        text="Submit Brand Registration"
        createFunction={createBrand}
        isDisbled={
          brandData?.status === TWILIO_STATUSES.APPROVED ||
          brandData?.status === TWILIO_STATUSES.REVIEW ||
          businessProfileData?.status === TWILIO_STATUSES.APPROVED ||
          !isCreateBusinessProfileSuccess ||
          isCreateBrandSuccess
        }
        notice={brandData?.status === TWILIO_STATUSES.REVIEW ? 'Pending review' : ''}
      />
      <SubmissionCard
        data={campaignData}
        isLoading={isCampaignDataFetching || isCreateCampaignLoading}
        isSuccess={isCreateCampaignSuccess}
        text="Submit A2P Campaign Registration"
        createFunction={createCampaign}
        isDisbled={
          campaignData?.status === TWILIO_STATUSES.APPROVED ||
          campaignData?.status === TWILIO_STATUSES.REVIEW ||
          brandData?.status === TWILIO_STATUSES.APPROVED ||
          !isCreateBrandSuccess ||
          isCreateCampaignSuccess
        }
        notice={
          campaignData?.status === TWILIO_STATUSES.REVIEW ? 'Pending review' : ''
        }
      />
      <SubmissionCard
        data={shakenStirData}
        isLoading={isShakenStirDataFetching || isCreateShakenStirLoading}
        isSuccess={isCreateShakenStirSuccess}
        text="Submit Shaken STIR Registration"
        createFunction={createShakenStir}
        isDisbled={
          shakenStirData?.status === TWILIO_STATUSES.APPROVED ||
          shakenStirData?.status === TWILIO_STATUSES.REVIEW ||
          businessProfileData?.status === TWILIO_STATUSES.APPROVED ||
          !isCreateBusinessProfileSuccess ||
          isCreateShakenStirSuccess
        }
        notice={
          shakenStirData?.status === TWILIO_STATUSES.REVIEW ? 'Pending review' : ''
        }
      />
      <SubmissionCard
        data={voiceIntegrityData}
        isLoading={isVoiceIntegrityDataFetching || isCreateVoiceIntegrityLoading}
        isSuccess={isCreateVoiceIntegritySuccess}
        text="Submit Voice Integrity Registration"
        createFunction={createVoiceIntegrity}
        isDisbled={
          voiceIntegrityData?.status === TWILIO_STATUSES.APPROVED ||
          voiceIntegrityData?.status === TWILIO_STATUSES.REVIEW ||
          businessProfileData?.status === TWILIO_STATUSES.APPROVED ||
          !isCreateBusinessProfileSuccess ||
          isCreateVoiceIntegritySuccess
        }
        notice={
          voiceIntegrityData?.status === TWILIO_STATUSES.REVIEW
            ? 'Pending review'
            : ''
        }
      />
      <SubmissionCard
        data={cnamData}
        isLoading={isCnamDataFetching || isCreateCnamLoading}
        isSuccess={isCreateCnamSuccess}
        text="Submit CNAM Registration"
        createFunction={createCnam}
        isDisbled={
          cnamData?.status === TWILIO_STATUSES.APPROVED ||
          cnamData?.status === TWILIO_STATUSES.REVIEW ||
          businessProfileData?.status === TWILIO_STATUSES.APPROVED ||
          !isCreateBusinessProfileSuccess ||
          isCreateCnamSuccess
        }
        notice={cnamData?.status === TWILIO_STATUSES.REVIEW ? 'Pending review' : ''}
      />
    </Grid>
  );
}
