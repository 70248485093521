import { useState, useEffect, useRef, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';

import { useOrg } from '../../contexts/OrgProvider';
import { useOrganizationsList } from '../services/organizations/queries';
import { useLeadLocksList } from '../services/leadLocks/queries';
import { useUsersCurrent } from '../services/users/queries';
import { useTasksList } from '../services/tasks/queries';
import { toParamString } from '../services/queryClientConfig';
import { hasLeadLock } from '../services/leadLocks/leadLocksUtils';
import { dateToPrettyDateString } from '../services/dateTime';

import { CellSpanStyle } from '../theme/styled/CellSpanStyle';
import {
  Box,
  Typography,
  Popover,
  Alert,
  Icon,
  Tabs,
  Tab,
  Collapse,
} from '@mui/material';

import {
  ClockAlertIcon,
  LockIcon,
  CheckboxChecked,
  CheckboxEmpty,
} from '../theme/icons';

// non-uplift components
import CustomFooter from '../components/Tasks/CustomFooter';
import FilterBar from '../components/Tasks/FilterBar';
import FilterButtonWrapper from '../components/Filters/FilterButtonWrapper';
import AddLeadButtonWrapper from '../components/Leads/AddLeadButtonWrapper';
import {
  TaskCardWrapper,
  NoTodoTasksOverlay,
  NoNonTodoTasksOverlay,
  TasksDataGridStyle,
  ActiveCellSpan,
} from '../components/Tasks/ui';
import { getLeadName } from '../services/leads/leadsUtils';
import {
  getStatusType,
  getAccountName,
  taskActionIconMap,
  parseTasksFilterParams,
} from '../components/Tasks/utils';

const Tasks = () => {
  const history = useHistory();
  const { taskIdParam } = useParams();
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);
  const [sortModel, setSortModel] = useState(() => {
    const saved = localStorage.getItem('tasks.sort_model');
    const initialValue = JSON.parse(saved);
    return initialValue || [];
  });
  const filterBarRef = useRef();
  const [tasksFilterMap, setTasksFilterMap] = useState({ status: null });
  const { id: orgId, isMasterAccount } = useOrg();
  const {
    data: accountsList,
    isError: isOrganizationsListError,
  } = useOrganizationsList();
  const { data: leadLocksList } = useLeadLocksList();
  const { data: currentUser } = useUsersCurrent();

  const [requestParams, filterParams] = useMemo(
    () => parseTasksFilterParams(tasksFilterMap, isMasterAccount, orgId),
    [tasksFilterMap, orgId]
  );
  const [showFilters, setFilterToggle] = useState(
    window.innerWidth <= 768 ? false : true
  );
  const [isTaskShowError, setTaskShowError] = useState(false);
  const [isError, setError] = useState(true);
  const [isUsersLoading, setIsUsersLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (isTaskShowError && isError) {
      if (location.pathname !== '/tasks') {
        setError(false);
      }
    }
  }, [isTaskShowError, isError, location]);

  function handleWindowSizeChange() {
    if (window.innerWidth <= 768) {
      setFilterToggle(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const getSorting = () => {
    if (tasksFilterMap.status === 'completed') {
      if (sortModel.length > 0) {
        return [
          [
            'order',
            `${sortModel[0].field} ${sortModel[0].sort}, tasks.completed_at desc`,
          ],
        ];
      } else {
        return [['order', `tasks.completed_at desc`]];
      }
    } else {
      if (sortModel.length > 0) {
        return [
          [
            'order',
            `${sortModel[0].field} ${sortModel[0].sort}, tasks.created_at desc`,
          ],
        ];
      } else {
        return [['order', `task_actions.created_at desc, tasks.created_at desc`]];
      }
    }
  };
  const createTasksListFilters = () => {
    const filters = [
      ['page', page],
      ['page_size', pageSize],
      ...getSorting(),
      ...filterParams,
    ];

    return filters;
  };
  const transformTasksList = (data) => {
    const appendedItems = data.items.map((task) => {
      return {
        ...task,
        lead_id: task.lead.id,
        account_name: getAccountName(task, accountsList),
        status: getStatusType(task),
      };
    });
    return { ...data, items: appendedItems };
  };

  const {
    data: tasksList,
    Error: isTasksListError,
    isFetching,
    refetch,
  } = useTasksList(createTasksListFilters(), {
    select: transformTasksList,
  });

  // temporary way to cause refresh without changing all context code in old components
  useEffect(() => {
    if (!taskIdParam) {
      refetch();
    }
  }, [taskIdParam]);

  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      hide: !!taskIdParam,
      sortable: false,
      maxWidth: 120,
      renderCell: ({ formattedValue }) => {
        return (
          <Box display="flex" alignItems="center" gap={0.8} pl={1}>
            {tasksFilterMap?.status === 'completed' && (
              <CheckboxChecked sx={{ mt: 0.1 }} />
            )}
            {tasksFilterMap?.status === null && <CheckboxEmpty sx={{ mt: 0.1 }} />}
            {formattedValue}
          </Box>
        );
      },
    },
    {
      field: 'task_actions.created_at',
      headerName: 'Action',
      maxWidth: 150,
      hide: !!taskIdParam,
      flex: 1,
      renderCell: ({ row }) => {
        let newName;
        if (row.task_action.name === 'FollowUp') {
          newName = 'Follow Up';
        } else if (row.task_action.name === 'ReEngage') {
          newName = 'Re-Engage';
        } else {
          newName = row.task_action.name;
        }

        return (
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            width="100%"
            height="100%"
            pl={1}
          >
            <Icon
              component={taskActionIconMap[row.task_action.name]}
              fontSize="small"
            />
            {newName}
          </Box>
        );
      },
    },
    {
      field: 'leads.first_name',
      headerName: 'Name',
      flex: 1,
      renderCell: (cell) => {
        const leadIsLocked = hasLeadLock({
          leadId: cell.row.lead.id,
          userId: currentUser.id,
          leadLocks: leadLocksList,
        });
        return (
          <ActiveCellSpan cell={cell}>
            <Box
              sx={{
                paddingLeft: '10px',
                paddingRight: '10px',
                dipslay: 'inline-block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >{`${getLeadName(cell.row.lead)}`}</Box>
            {leadIsLocked && <LockIcon color="error" fontSize="small" />}
          </ActiveCellSpan>
        );
      },
    },
    {
      field: 'Overdue Icon',
      headerName: '',
      maxWidth: 20,
      minWidth: 20,
      hide: !!taskIdParam || tasksFilterMap.status === 'completed',
      sortable: false,
      renderCell: ({ row }) => {
        const dueAtLocalTime = moment(row.due_at).tz(moment.tz.guess());
        const isOverdue = dueAtLocalTime.isBefore(Date.now(), 'day');
        return (
          <div>
            {isOverdue && !row.completed_at && !row.canceled_at && (
              <ClockAlertIcon color="error" fontSize="small" />
            )}
          </div>
        );
      },
    },
    {
      field: 'due_at',
      headerName: 'Due At',
      flex: 1,
      sortable: false,
      maxWidth: 200,
      hide: !!taskIdParam || tasksFilterMap.status === 'completed',
      renderCell: ({ formattedValue }) => {
        return (
          <CellSpanStyle>
            <Typography fontSize={14}>{`${
              tasksFilterMap?.status ? '' : dateToPrettyDateString(formattedValue)
            }`}</Typography>
          </CellSpanStyle>
        );
      },
    },
    {
      field: 'tasks.completed_at',
      headerName: 'Completed At',
      flex: 1,
      maxWidth: 200,
      hide: !!taskIdParam || tasksFilterMap.status !== 'completed',
      renderCell: ({ row }) => {
        return (
          <CellSpanStyle>
            {!!row.completed_at && (
              <Typography fontSize={14}>
                {dateToPrettyDateString(row.completed_at)}
              </Typography>
            )}
          </CellSpanStyle>
        );
      },
    },
    {
      field: 'task_sequences.name',
      headerName: 'Sequence',
      flex: 1,
      maxWidth: 200,
      hide: !!taskIdParam,
      renderCell: ({ row }) => {
        return <CellSpanStyle>{`${row.task_sequence?.name ?? ''}`}</CellSpanStyle>;
      },
    },
    {
      field: 'leads.created_at',
      headerName: 'Lead Created',
      flex: 1,
      maxWidth: 200,
      hide: !!taskIdParam,
      renderCell: ({ row }) => {
        return (
          <CellSpanStyle>
            {!!row.lead.created_at && (
              <Typography fontSize={14}>
                {dateToPrettyDateString(row.lead.created_at)}
              </Typography>
            )}
          </CellSpanStyle>
        );
      },
    },
    {
      field: 'leads.organization_id',
      headerName: 'Account',
      flex: 1,
      maxWidth: 200,
      hide: !!taskIdParam,
      renderCell: ({ row }) => {
        return <CellSpanStyle>{row.account_name}</CellSpanStyle>;
      },
    },
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState('');

  useEffect(() => {
    localStorage.setItem('tasks.sort_model', JSON.stringify(sortModel));
  }, [sortModel]);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    localStorage.setItem('tasks.sort_model', JSON.stringify(sortModel));
  }, [sortModel]);

  const open = Boolean(anchorEl);
  const currentPath = window.location.pathname;
  const shouldShowFilters = currentPath === '/tasks';

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography fontSize={18} fontWeight={700}>
          Tasks
        </Typography>
        <Box display="flex" alignItems="center">
          <Box sx={{ marginLeft: '10px' }}>
            {shouldShowFilters && (
              <FilterButtonWrapper
                showFilters={showFilters}
                setFilterToggle={setFilterToggle}
              />
            )}
            <AddLeadButtonWrapper />
          </Box>
        </Box>
      </Box>
      {isTasksListError && !isOrganizationsListError && (
        <Alert severity="error">
          There was an error loading your tasks. Try again.
        </Alert>
      )}
      {isTaskShowError && (
        <Collapse in={isError}>
          <Alert onClose={() => setError(false)} severity="error">
            Task not found
          </Alert>
        </Collapse>
      )}
      <Box display="flex" height="0%" width="100%" flexBasis="100%" gap={2}>
        {showFilters ? (
          <FilterBar
            setPage={setPage}
            ref={filterBarRef}
            setTasksFilterMap={setTasksFilterMap}
            setIsUsersLoading={setIsUsersLoading}
          />
        ) : (
          <></>
        )}
        <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setAnchorEl(null);
          }}
          disableRestoreFocus
        >
          <Typography fontSize={14} sx={{ px: 1, py: 0.5 }}>{`${value}`}</Typography>
        </Popover>
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          width="auto"
          border={({ palette }) =>
            !taskIdParam ? `1px solid${palette.grey[300]}` : 'none'
          }
          borderRadius="4px"
          sx={{
            px: taskIdParam ? 0 : 3,
            pb: taskIdParam ? 0 : 3,
            background: ({ palette }) => palette.grey[100],
          }}
        >
          {!taskIdParam && (
            <Box
              borderRadius="inherit"
              display="flex"
              alignItems="center"
              height={60}
              sx={{
                backgroundColor: ({ palette }) => palette.grey[100],
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                width: '100%',
                py: 1,
              }}
            >
              <Tabs
                sx={{
                  height: '36px',
                  minHeight: '36px',
                  '& .MuiTabs-indicator': {
                    height: '36px',
                    minHeight: '36px',
                    borderRadius: '4px',
                  },
                  '& .MuiButtonBase-root': {
                    zIndex: 5,
                    height: '36px',
                    minHeight: '36px',
                    borderRadius: '4px',
                  },
                  '& .Mui-selected': {
                    color: 'white !important',
                    transition: (theme) =>
                      theme.transitions.create('color', {
                        duration: 700,
                      }),
                  },
                }}
                value={(() => {
                  if (tasksFilterMap.status === null) return 'todo';
                  if (tasksFilterMap.status === 'completed') return 'completed';
                  return false;
                })()}
                onChange={(e, newVal) => {
                  if (newVal === 'todo') {
                    filterBarRef.current.changeFilterParams({
                      status: null,
                      sql_operator: null,
                      completed_at_start: '',
                      completed_at_end: '',
                    });
                  }
                  if (newVal === 'completed') {
                    filterBarRef.current.changeFilterParams({
                      status: 'completed',
                      sql_operator: null,
                      completed_at_start: moment(new Date())
                        .startOf('day')
                        .toISOString(),
                      completed_at_end: moment(new Date())
                        .endOf('day')
                        .toISOString(),
                    });
                  }
                }}
              >
                <Tab label="To Do" value="todo" />
                <Tab label="Completed" value="completed" />
              </Tabs>
            </Box>
          )}
          <TasksDataGridStyle
            showNoActionCursor={tasksFilterMap?.status === 'canceled'}
            disableSelectionOnClick
            components={{
              Footer: () => (
                <CustomFooter
                  rowCount={tasksList?.total_count ?? 0}
                  page={page - 1}
                  onPageChange={(page) => setPage(page + 1)}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                  organization_id={orgId}
                  requested_url={`${
                    process.env.REACT_APP_API_BASE
                  }/v2/tasks?${toParamString(requestParams)}`}
                  isCurrentUserAdmin={currentUser.roles.some(
                    (role) => role.role_name === 'Administrator'
                  )}
                />
              ),
              NoRowsOverlay:
                tasksFilterMap.status === null
                  ? NoTodoTasksOverlay
                  : NoNonTodoTasksOverlay,
            }}
            componentsProps={{
              row: {
                onClick: (e) => {
                  if (tasksFilterMap?.status !== 'canceled') {
                    history.push(`/tasks/${e.currentTarget.dataset.id}`);
                  }
                },
              },
            }}
            rows={tasksList?.items ?? []}
            rowCount={tasksList?.total_count ?? 0}
            columns={columns}
            loading={isFetching || isUsersLoading}
            rowsPerPageOptions={[10, 25, 50]}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={(newModel) => {
              setPage(1);
              setSortModel(newModel);
            }}
            paginationMode="server"
            page={page - 1}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            disableColumnFilter
            disableColumnMenu
            density="compact"
            taskIdParam={taskIdParam}
            sx={{
              // custom style for selected cell
              '& .MuiDataGrid-cell': {
                outline: 'none',
                padding: 0,
                position: 'relative',
              },
            }}
          />
        </Box>
        {taskIdParam && <TaskCardWrapper setTaskShowError={setTaskShowError} />}
      </Box>
    </Box>
  );
};

export default Tasks;
