import React, { useState } from 'react';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import { SettingsCard } from '../../pages/_settingsPagesComponents/SettingsCard';
import { useReportsList } from '../../services/reports/queries';

const isMultilineReport = (reportType) =>
  reportType.endsWith('_by_user') ||
  reportType.endsWith('_by_team') ||
  reportType.endsWith('_by_source') ||
  reportType.endsWith('_by_reason') ||
  reportType.endsWith('_by_actor');

const ReportValue = ({ filters, reportType, reportValue }) => {
  const filtersObj = Object.fromEntries(filters);

  let start, end;
  if (
    filtersObj['date_range_start']?._isAMomentObject &&
    filtersObj['date_range_end']?._isAMomentObject
  ) {
    start = moment(filtersObj['date_range_start']?.startOf('day'))
      ?.utc()
      ?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    end = moment(filtersObj['date_range_end']?.endOf('day'))
      ?.utc()
      ?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  } else {
    start = filtersObj['date_range_start'];
    end = filtersObj['date_range_end'];
  }

  const appendFilters = () => {
    const reportsFilterTypes = [
      'source_name[]',
      'lost_reason_name[]',
      'team_id[]',
      'organization_id[]',
    ];

    return reportsFilterTypes
      .map((filterType) => {
        const filtered = filters.filter((el) => el[0] === filterType);
        return filtered.map((el) => `&${el[0]}=${el[1]}`).join('');
      })
      .join('');
  };

  const dataUrls = {
    acn: `/activity?event_type=call_slingshot_initiated&min_dttm=${start}&max_dttm=${end}${appendFilters()}`,
    acn_accepted: `/activity?event_type=call_slingshot_accepted&min_dttm=${start}&max_dttm=${end}${appendFilters()}`,
    automated_email_sent: `/activity?event_type=email_outbound&actor_user_id=null&min_dttm=${start}&max_dttm=${end}${appendFilters()}`,
    automated_text_messages_sent: `/activity?event_type=text_outbound&actor_user_id=null&min_dttm=${start}&max_dttm=${end}${appendFilters()}`,
    call_answered: `/activity?event_type=call_inbound_answered&min_dttm=${start}&max_dttm=${end}${appendFilters()}`,
    call_made: `/activity?event_type=call_outbound&min_dttm=${start}&max_dttm=${end}${appendFilters()}`,
    email_received: `/activity?event_type=email_inbound&min_dttm=${start}&max_dttm=${end}${appendFilters()}`,
    email_sent: `/activity?event_type=email_outbound&min_dttm=${start}&max_dttm=${end}${appendFilters()}`,
    lead_generated: `/leads?created_at_selector=16&created_at_start=${start}&created_at_end=${end}${appendFilters()}`,
    lead_won: `/leads?created_at_selector=16&created_at_start=${start}&created_at_end=${end}${appendFilters()}&status=1`,
    text_messages_received: `/activity?event_type=text_inbound&min_dttm=${start}&max_dttm=${end}${appendFilters()}`,
    text_messages_sent: `/activity?event_type=text_outbound&min_dttm=${start}&max_dttm=${end}${appendFilters()}`,
  };

  const { pathname } = useLocation();

  const renderDollarsFromCents = (centAmount) => {
    const dollars = Math.round(centAmount / 100);
    return dollars.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const renderPercentsFromNumber = (string) => {
    if (isNaN(Number(string))) {
      return string;
    } else {
      return Number(string).toLocaleString('en-US') + '%';
    }
  };

  const renderFormatedNumber = (string) => {
    if (isNaN(Number(string))) {
      return string;
    } else {
      return Number(string).toLocaleString('en-US');
    }
  };

  const formattedReportValue = (reportType, reportValue) => {
    if (isMultilineReport(reportType)) {
      return reportType.includes('_rate')
        ? renderPercentsFromNumber(reportValue)
        : renderFormatedNumber(reportValue);
    } else {
      return ['lead_worth', 'win_worth', 'pipeline_value'].includes(reportType)
        ? renderDollarsFromCents(reportValue)
        : reportType.includes('_rate') || reportType.includes('_conversion_with')
        ? renderPercentsFromNumber(reportValue)
        : renderFormatedNumber(reportValue);
    }
  };

  if (
    pathname !== '/reports/conversion' &&
    reportValue !== 'No data' &&
    Object.keys(dataUrls).includes(reportType)
  ) {
    return (
      <Link to={dataUrls[reportType]} style={{ color: 'black' }}>
        {formattedReportValue(reportType, reportValue)}
      </Link>
    );
  }

  return <>{formattedReportValue(reportType, reportValue)}</>;
};

const ReportCard = ({ report, params }) => {
  const [reportValues, setReportValues] = useState();
  const { isLoading } = useReportsList(params, setReportValues);

  return (
    <SettingsCard
      sx={{
        p: 3,
        width: '250px',
        boxShadow:
          'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
        borderRadius: '16px',
        color: 'rgb(33, 43, 54)',
      }}
    >
      <Box sx={{ mb: 2, height: '40px' }}>
        <Typography variant="subtitle2">{report.name}</Typography>
      </Box>
      {isMultilineReport(report.type) ? (
        <>
          {isLoading ? (
            <Skeleton height={36} />
          ) : reportValues?.value.length == 0 ? (
            <Box sx={{ fontWeight: 500 }}>No data</Box>
          ) : (
            <Box sx={{ overflowY: 'auto', maxHeight: '240px' }}>
              {reportValues?.value?.map((reportRow, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography sx={{ fontWeight: 500 }}>{reportRow.name}</Typography>
                  <Typography>
                    <ReportValue
                      filters={params.filters}
                      reportType={report.type}
                      reportValue={reportRow.value}
                    />
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </>
      ) : (
        <>
          {isLoading ? (
            <Skeleton height={36} />
          ) : (
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              <ReportValue
                filters={params.filters}
                reportType={report.type}
                reportValue={reportValues?.value}
              />
            </Typography>
          )}
        </>
      )}
    </SettingsCard>
  );
};

export default ReportCard;
