import BillingAddress from './BillingAddresses';
import { useMutation, useQueryClient } from 'react-query';
import { mutationKeyFactory } from '../queryClientConfig';

const billingAddressKeys = mutationKeyFactory('billing_addresses');

export const useBillingAddressEdit = (options) => {
  const queryClient = useQueryClient();
  return useMutation((billingAddress) => BillingAddress.edit(billingAddress), {
    mutationKey: billingAddressKeys.edit(),
    onSuccess: () => {
      queryClient.invalidateQueries(billingAddressKeys.all);
    },
    ...options,
  });
};
