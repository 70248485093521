import React, { useEffect, useState } from 'react';
import { useOrg } from '../../contexts/OrgProvider';
import { GridFooterContainer } from '@mui/x-data-grid';
import TablePagination from '@mui/material/TablePagination';
import BillingResources from '../services/billingResources/BillingResources';
import { useBillingResourcesList } from '../services/billingResources/queries';

import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { DataGridStyle } from '../theme/styled/DataGridStyle';
import { CellSpanStyle } from '../theme/styled/CellSpanStyle';
import { DownloadIcon } from '../theme/icons';

const BillingResourcesPage = () => {
  const [billingResourcesPageSize, setBillingResourcesPageSize] = useState(10);
  const [billingResourcesPage, setBillingResourcesPage] = useState(1);

  const { id: orgId, isMasterAccount, enterprise_id } = useOrg();
  const createBillingResourcesFilters = () => {
    const filters = [
      ['page', billingResourcesPage],
      ['page_size', billingResourcesPageSize],
    ];
    if (isMasterAccount) {
      filters.push(['enterprise_id', enterprise_id]);
    } else {
      filters.push(['organization_id', orgId]);
    }
    return filters;
  };
  const {
    data: billingResourcesList,
    isError: isBillingResourcesError,
    isFetching: isBillingResourcesFetching,
  } = useBillingResourcesList(createBillingResourcesFilters());

  const billingResourcesColumns = [
    {
      field: 'name',
      headerName: 'Account',
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'new_patient_manager_enabled',
      headerName: 'New Patient Manager Enabled',
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      renderCell: ({ row }) => (
        <CellSpanStyle sx={{ width: '100%', textAlign: 'center' }}>
          {row.new_patient_manager_enabled ? <>&#x2705;</> : <>&#x274C;</>}
        </CellSpanStyle>
      ),
    },
    {
      field: 'ai_enabled',
      headerName: 'AI Enabled',
      sortable: false,
      disableColumnMenu: true,
      width: 90,
      renderCell: ({ row }) => (
        <CellSpanStyle sx={{ width: '100%', textAlign: 'center' }}>
          {row.ai_enabled ? <>&#x2705;</> : <>&#x274C;</>}
        </CellSpanStyle>
      ),
    },
    {
      field: 'call_tracking_enabled',
      headerName: 'Call Tracking Enabled',
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      renderCell: ({ row }) => (
        <CellSpanStyle sx={{ width: '100%', textAlign: 'center' }}>
          {row.call_tracking_enabled ? <>&#x2705;</> : <>&#x274C;</>}
        </CellSpanStyle>
      ),
    },
    {
      field: 'teams',
      headerName: 'Teams',
      sortable: false,
      disableColumnMenu: true,
      width: 90,
      renderCell: ({ row }) => (
        <CellSpanStyle sx={{ width: '100%', textAlign: 'center' }}>
          {row.teams.length}
        </CellSpanStyle>
      ),
    },
    {
      field: 'phones_with_phone_pools_quantity',
      headerName: 'Phones With Phone Pools',
      sortable: false,
      disableColumnMenu: true,
      width: 115,
      renderCell: ({ row }) => (
        <CellSpanStyle sx={{ width: '100%', textAlign: 'center' }}>
          {row.phones_with_phone_pools_quantity}
        </CellSpanStyle>
      ),
    },
    {
      field: 'phones_with_no_phone_pools_quantity',
      headerName: 'Phones With No Phone Pools',
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      renderCell: ({ row }) => (
        <CellSpanStyle sx={{ width: '100%', textAlign: 'center' }}>
          {row.phones_with_no_phone_pools_quantity}
        </CellSpanStyle>
      ),
    },
    {
      field: 'phone_pools_quantity',
      headerName: 'Phone Pools',
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      renderCell: ({ row }) => (
        <CellSpanStyle sx={{ width: '100%', textAlign: 'center' }}>
          {row.phone_pools_quantity}
        </CellSpanStyle>
      ),
    },
    {
      field: 'ai_minutes_prev_month',
      headerName: 'AI Minutes Used (previous month)',
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      renderCell: ({ row }) => (
        <CellSpanStyle sx={{ width: '100%', textAlign: 'center' }}>
          {row.ai_minutes_prev_month}
        </CellSpanStyle>
      ),
    },
    {
      field: 'ai_minutes_this_month',
      headerName: 'AI Minutes Used (this month)',
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      renderCell: ({ row }) => (
        <CellSpanStyle sx={{ width: '100%', textAlign: 'center' }}>
          {row.ai_minutes_this_month}
        </CellSpanStyle>
      ),
    },
  ];

  return (
    <>
      {isBillingResourcesError ? (
        <Alert severity="error">
          There was a problem loading billing resources. Try again.
        </Alert>
      ) : (
        <SettingsCard showLoading={isBillingResourcesFetching}>
          <SettingsCard.Header>Billing</SettingsCard.Header>
          <SettingsCard.Main>
            {billingResourcesList?.items.length > 1 ? (
              <DataGridStyle
                rows={billingResourcesList?.items ?? []}
                columns={billingResourcesColumns}
                loading={isBillingResourcesFetching}
                autoHeight
                density="compact"
                sx={{
                  '& .MuiDataGrid-row': {
                    cursor: 'default',
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    whiteSpace: 'normal',
                    lineHeight: 'normal',
                    textAlign: 'center',
                  },
                  '& .MuiDataGrid-columnHeaderTitleContainer': {
                    justifyContent: 'center',
                  },
                }}
                components={{
                  Footer: () => (
                    <CustomFooter
                      rowCount={billingResourcesList?.total_count ?? 0}
                      page={billingResourcesPage - 1}
                      onPageChange={(billingResourcesPage) =>
                        setBillingResourcesPage(billingResourcesPage + 1)
                      }
                      onPageSizeChange={setBillingResourcesPageSize}
                      pageSize={billingResourcesPageSize}
                      enterprise_id={enterprise_id}
                    />
                  ),
                }}
              />
            ) : billingResourcesList?.items.length === 1 ? (
              <BillingResourcesList
                billingResource={billingResourcesList.items[0]}
              />
            ) : (
              <Alert severity="info">
                No billing resources for this organization.
              </Alert>
            )}
          </SettingsCard.Main>
        </SettingsCard>
      )}
    </>
  );
};

const CustomFooter = ({
  rowCount,
  page,
  onPageChange,
  onPageSizeChange,
  pageSize,
  enterprise_id,
}) => {
  const [csvObjectKey, setCsvObjectKey] = useState();
  const [isCsvObjectKeyLoading, setIsCsvObjectKeyLoading] = useState(false);

  const handlePageChange = (_, newPage) => {
    onPageChange(newPage);
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    onPageSizeChange(newPageSize);
  };

  const downloadCSV = () => {
    setIsCsvObjectKeyLoading(true);
    BillingResources.generateCSV(enterprise_id, setCsvObjectKey);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const csvUrl = await BillingResources.getCSVUrl(csvObjectKey);
        if (csvUrl) {
          const response = await fetch(csvUrl);
          if (response.ok) {
            const tempLink = document.createElement('a');
            tempLink.href = csvUrl;
            tempLink.download = csvObjectKey;
            tempLink.click();
            setIsCsvObjectKeyLoading(false);
            setCsvObjectKey();
            document.body.removeChild(tempLink);
          }
        }
      } catch (error) {
        // Catch and ignore error
      }
    };

    if (csvObjectKey) {
      const fiveSeconds = 5000;
      const intervalId = setInterval(() => {
        fetchData();
      }, fiveSeconds);

      fetchData();

      return () => clearInterval(intervalId);
    }
  }, [csvObjectKey]);

  return (
    <GridFooterContainer>
      <TablePagination
        component="div"
        count={rowCount}
        page={page}
        rowsPerPage={pageSize}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handlePageSizeChange}
        rowsPerPageOptions={[10, 25, 50]}
        style={{ marginRight: 'auto' }}
      />
      <IconButton sx={{ marginRight: '3rem' }} onClick={() => downloadCSV()}>
        {isCsvObjectKeyLoading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <DownloadIcon />
        )}
      </IconButton>
    </GridFooterContainer>
  );
};

const BillingResourcesList = ({ billingResource }) => {
  return (
    <Box sx={{ width: '50%', marginX: 'auto' }}>
      <Grid container component="h3">
        <Grid item>{billingResource.name}</Grid>
      </Grid>
      <Grid container component="dl" sx={{ marginTop: '12px', gap: '12px' }}>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography component="dt" variant="h6">
            New Patient Manager Enabled
          </Typography>
          <Typography component="dd">
            {billingResource.new_patient_manager_enabled ? (
              <>&#x2705;</>
            ) : (
              <>&#x274C;</>
            )}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography component="dt" variant="h6">
            AI Enabled
          </Typography>
          <Typography component="dd">
            {billingResource.ai_enabled ? <>&#x2705;</> : <>&#x274C;</>}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography component="dt" variant="h6">
            Call Tracking Enabled
          </Typography>
          <Typography component="dd">
            {billingResource.call_tracking_enabled ? <>&#x2705;</> : <>&#x274C;</>}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography component="dt" variant="h6">
            Teams
          </Typography>
          <Typography component="dd" variant="body1">
            {billingResource.teams.length}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography component="dt" variant="h6">
            Phones With Phone Pools
          </Typography>
          <Typography component="dd" variant="body1">
            {billingResource.phones_with_phone_pools_quantity}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography component="dt" variant="h6">
            Phones With No Phone Pools
          </Typography>
          <Typography component="dd" variant="body1">
            {billingResource.phones_with_no_phone_pools_quantity}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography component="dt" variant="h6">
            Phone Pools
          </Typography>
          <Typography component="dd" variant="body1">
            {billingResource.phone_pools_quantity}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography component="dt" variant="h6">
            AI Minutes Used (previous month)
          </Typography>
          <Typography component="dd" variant="body1">
            {billingResource.ai_minutes_prev_month}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography component="dt" variant="h6">
            AI Minutes Used (this month)
          </Typography>
          <Typography component="dd" variant="body1">
            {billingResource.ai_minutes_this_month}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingResourcesPage;
