import { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
} from '@mui/material';

import LeadSearch from '../../MUI/components/LeadSearch';
import { routerPathWithoutUuid } from '../../util/AppUtils';
import { getActiveOrg, getActiveOrgName, setActiveOrg } from '../../util/UserUtils';
import { AiOutlineMenu } from 'react-icons/ai';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { useLayout } from '../../MUI/contexts/LayoutProvider';
import { useUsersCurrent } from '../../MUI/services/users/queries';
import { SearchIcon, MenuDownIcon } from '../../MUI/theme/icons';

const TopNavigationGroup = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { data: user } = useUsersCurrent();
  const { mobileNavIsOpen, setMobileNavIsOpen } = useLayout();

  useEffect(() => {
    window.addEventListener('resize', (event) => {
      setIsMobile(event.target.innerWidth <= 900);
    });
    return () => {
      window.removeEventListener('resize', (event) => {
        setIsMobile(event.target.innerWidth <= 900);
      });
    };
  }, []);

  const handleOnOrganizationClick = (org) => {
    setActiveOrg(org);
    window.location.pathname = routerPathWithoutUuid();
  };

  const OrgPicker = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const open = Boolean(anchorEl);

    const filteredOrgs =
      user?.authorizations?.table?.organizations?.filter((org) => {
        if (user?.admin) {
          return (
            org.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            String(org.id).includes(searchTerm)
          );
        } else {
          return org.name.toLowerCase().includes(searchTerm.toLowerCase());
        }
      }) || [];

    return (
      <>
        <Button
          variant="text"
          sx={{ color: 'inherit', fontSize: 'inherit', fontWeight: 'normal' }}
          tabIndex={isMobile ? -1 : 0}
          endIcon={
            <MenuDownIcon
              sx={{
                width: !isMobile || isMobileMenuOpen ? '30px' : '0px',
                height: !isMobile || isMobileMenuOpen ? '30px' : '0px',
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.4s, width 0.4s, height 0.4s',
              }}
            />
          }
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {getActiveOrgName()}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          sx={{ minWidth: 200 }}
        >
          {user?.authorizations?.table?.organizations?.length > 5 && (
            <Box>
              <TextField
                autoFocus
                sx={{
                  margin: '4px 24px',
                  width: 'calc(100% - 48px)',
                  '& fieldset': { top: 0 },
                  '& legend': { display: 'none' },
                }}
                placeholder="Search name or ID"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Divider />
            </Box>
          )}
          {user?.authorizations?.table?.organizations?.length > 1 && (
            <Box>
              <Box
                className="top-nav-org-list"
                sx={{ minWidth: '250px', maxHeight: '320px', overflowY: 'auto' }}
              >
                {filteredOrgs.length > 0 ? (
                  filteredOrgs.map((org) => {
                    if (org.id != getActiveOrg()) {
                      return (
                        <MenuItem
                          key={org.id}
                          onClick={(_) => {
                            handleOnOrganizationClick(org);
                          }}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 1,
                          }}
                        >
                          {org.name}
                          {user?.admin && <Chip size="small" label={org.id} />}
                        </MenuItem>
                      );
                    }
                  })
                ) : (
                  <Box sx={{ margin: '4px 24px', textAlign: 'center' }}>
                    No organizations found
                  </Box>
                )}
              </Box>
              <Divider />
            </Box>
          )}
          <MenuItem
            component="a"
            href="https://support.leadsigma.com"
            target="_blank"
          >
            Help
          </MenuItem>
          <MenuItem component="a" href={process.env.REACT_APP_LOGOUT_URL}>
            Logout
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <>
      <AppBar
        sx={{
          zIndex: 255,
          position: 'relative',
          backgroundColor: 'white',
          boxShadow: 'none',
          color: '#212529',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: { md: '72px' },
            padding: { xs: '8px 16px 8px 0' },
            paddingLeft: { md: '16px' },
          }}
        >
          {isMobile && (
            <Button
              variant="text"
              sx={{
                color: 'inherit',
                minWidth: 'auto',
                padding: '2px 12px',
              }}
              onClick={() => setMobileNavIsOpen((prev) => !prev)}
            >
              <AiOutlineMenu size={30} />
            </Button>
          )}
          <LeadSearch />
          {isMobile ? (
            <Button
              variant="text"
              sx={{
                color: 'inherit',
                minWidth: 'auto',
                padding: '2px 12px',
              }}
              onClick={() => setIsMobileMenuOpen((prev) => !prev)}
            >
              <BiDotsVerticalRounded size="40px" />
            </Button>
          ) : (
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'end' }}>
              <OrgPicker />
            </Box>
          )}
        </Toolbar>
        {isMobile && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              position: 'relative',
              overflow: isMobileMenuOpen ? 'visible' : 'hidden',
              fontSize: isMobileMenuOpen ? '16px' : '0px',
              height: isMobileMenuOpen ? '40px' : '0px',
              transition: 'height 0.4s, font-size 0.4s',
            }}
          >
            <OrgPicker />
          </Box>
        )}
      </AppBar>
    </>
  );
};

export default TopNavigationGroup;
