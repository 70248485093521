import { useQuery } from 'react-query';
import { FIVE_MINUTES, queryKeyFactory } from '../queryClientConfig';
import Tasks from './Tasks';
import { useOrg } from '../../../contexts/OrgProvider';

export const tasksQueryKeys = queryKeyFactory('tasks');

export const useTasksList = (filters, options) => {
  const { id: orgId, isMasterAccount } = useOrg();
  if (!isMasterAccount) {
    filters.push(['organization_id', `eq::${orgId}`]);
  } else {
    filters.push(['primary_organization_id', orgId]);
  }
  return useQuery(tasksQueryKeys.list(filters), () => Tasks.list(filters), {
    staleTime: 0,
    ...options,
  });
};

export const useTasksShow = (id, options) => {
  return useQuery(tasksQueryKeys.show(id), () => Tasks.show(id), {
    enabled: !!id,
    ...options,
  });
};
