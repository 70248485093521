import React, { useContext, seEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import SyncLoader from 'react-spinners/SyncLoader';

import Task from '../../clients/Task';
import Note from '../../clients/Note';
import { dateToAbbreviatedDateTimeString } from '../../MUI/services/dateTime';

const NoteDetail = ({ note }) => {
  const [isDeletingNote, setIsDeletingNote] = useState(false);
  const handleDeleteNote = (note_id) => {
    if (window.confirm(`Do you really want to delete this note?`)) {
      setIsDeletingNote(true);
      Note.delete(note_id).then(() => {
        setIsDeletingNote(false);
      });
    }
  };

  return (
    <>
      <Row className={'m-2'}>
        <Col className={'p-3 bg-sigma-light'}>{note.value}</Col>
      </Row>
      <Row>
        <Col xs={8} className={'px-4 py-1'}>
          <i>{dateToAbbreviatedDateTimeString(note.created_at)}</i>
        </Col>
        <Col xs={4} className={'px-4 py-1 text-right'}>
          <Button
            variant={'danger'}
            className={'btn-sm'}
            onClick={() => handleDeleteNote(note.id)}
          >
            {isDeletingNote ? <SyncLoader color={'white'} size={'10'} /> : 'Delete'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default NoteDetail;
